import { ApolloError, gql, MutationFunction, MutationResult } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { Box, Button, IconButton, Stack, styled, TableCell, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import { default as React } from 'react';
import { Edit, Plus, Trash } from 'react-feather';
import { v4 as uuidv4 } from 'uuid';
import { Confirm } from '../components/common/Confirm';
import { DataTable, DataTableColumn } from '../components/common/DataTable';
import { createUseState } from '../components/common/UseState';
import { TypeForm } from '../forms/Type';
import { parseErrors } from '../lib/helpers';
import { ValidationError } from '../types';

const UseStateString = createUseState<string>();
const UseStateBoolean = createUseState<boolean>();
const UseStateValidationErrors = createUseState<ValidationError[]>();

const TYPES = gql`
    query Types(
        $search: String
        $skip: Int
        $take: Int
        $sortBy: String
        $sortOrder: String
    ) {
        types(
            search: $search
            skip: $skip
            take: $take
            sortBy: $sortBy
            sortOrder: $sortOrder
        ) {
            count
            edges {
                id
                name
                code
                requiredDrops
                description
                createdAt
                places {
                    edges {
                        id
                    }
                }
            }
        }
    }
`;

const CREATE_TYPE = gql`
    mutation CreateType(
        $name: String
        $code: String
        $requiredDrops: Int
        $description: String
        $placeIds: [String!]
    ) {
        createType(input: {
            name: $name
            code: $code
            requiredDrops: $requiredDrops
            description: $description
            placeIds: $placeIds
        }) {
            id
        }
    }
`;

const UPDATE_TYPE = gql`
    mutation UpdateType(
        $id: String!
        $name: String
        $code: String
        $requiredDrops: Int
        $description: String
        $placeIds: [String!]
    ) {
        updateType(input: {
            id: $id
            name: $name
            code: $code
            requiredDrops: $requiredDrops
            description: $description
            placeIds: $placeIds
        }) {
            id
        }
    }
`;

const DELETE_TYPE = gql`
    mutation DeleteType($id: String!) {
        deleteType(id: $id) {
            id
        }
    }
`;

const StyledButton = styled(Button)(() => ({
    lineHeight: '24px'
}));

export const Types = (): JSX.Element => {
    return (
        <UseStateString defaultValue={uuidv4()}>
            {(key, setKey) => (
                <UseStateString defaultValue="">
                    {(search, setSearch) => (
                        <div>
                            <Typography variant="h4">
                                Модны төрөл
                            </Typography>
                            <Box marginY={2}>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <UseStateBoolean defaultValue={false}>
                                        {(open, setOpen) => (
                                            <UseStateValidationErrors defaultValue={[]}>
                                                {(errors, setErrors) => (
                                                    <React.Fragment>
                                                        <Mutation
                                                            mutation={CREATE_TYPE}
                                                            onError={(err: ApolloError) => {
                                                                if (err.message === 'Bad Request Exception') {
                                                                    setErrors(parseErrors(err));
                                                                }
                                                            }}
                                                            onCompleted={() => {
                                                                setOpen(false);
                                                                setKey(uuidv4());
                                                            }}
                                                        >
                                                            {(mutate: MutationFunction, { loading }: MutationResult) => (
                                                                <TypeForm
                                                                    open={open}
                                                                    onClose={() => setOpen(false)}
                                                                    initialValues={{
                                                                        name: '',
                                                                        code: '',
                                                                        requiredDrops: null,
                                                                        description: '',
                                                                        placeIds: []
                                                                    }}
                                                                    errors={errors}
                                                                    onSubmit={values => {
                                                                        setErrors([]);
                                                                        mutate({
                                                                            variables: values
                                                                        });
                                                                    }}
                                                                    submitButtonProps={{
                                                                        loading
                                                                    }}
                                                                />
                                                            )}
                                                        </Mutation>
                                                        <StyledButton
                                                            variant="contained"
                                                            size="large"
                                                            disableElevation
                                                            startIcon={
                                                                <Plus />
                                                            }
                                                            onClick={() => {
                                                                setErrors([]);
                                                                setOpen(true);
                                                            }}
                                                        >
                                                            Шинэ
                                                        </StyledButton>
                                                    </React.Fragment>
                                                )}
                                            </UseStateValidationErrors>
                                        )}
                                    </UseStateBoolean>
                                    <TextField
                                        label="Хайх"
                                        size="small"
                                        variant="outlined"
                                        onChange={debounce(e => {
                                            setSearch(e.target.value);
                                        }, 500)}
                                        sx={{
                                            minWidth: 300
                                        }}
                                        InputProps={{
                                            sx: {
                                                backgroundColor: '#fff'
                                            }
                                        }}
                                    />
                                </Stack>
                            </Box>
                            <DataTable
                                key={key}
                                query={TYPES}
                                dataPath="types"
                                variables={{
                                    search
                                }}
                            >
                                <DataTableColumn
                                    title="Нэр"
                                    dataIndex="name"
                                    align="left"
                                    sorter
                                />
                                <DataTableColumn
                                    title="Код"
                                    dataIndex="code"
                                    align="left"
                                    sorter
                                />
                                <DataTableColumn
                                    title="Нийт ус"
                                    dataIndex="requiredDrops"
                                    align="left"
                                    sorter
                                    render={({ requiredDrops }) => (
                                        <TableCell padding="normal">
                                            {requiredDrops}л
                                        </TableCell>
                                    )}
                                />
                                <DataTableColumn
                                    title="Огноо"
                                    dataIndex="createdAt"
                                    align="left"
                                    sorter
                                    render={({ createdAt }) => (
                                        <TableCell padding="normal">
                                            {format(createdAt, 'yyyy-MM-dd HH:mm:ss')}
                                        </TableCell>
                                    )}
                                />
                                <DataTableColumn
                                    title=""
                                    dataIndex="id"
                                    align="right"
                                    render={({ id, name, code, requiredDrops, description, places }) => (
                                        <TableCell padding="none">
                                            <Box paddingX={2}>
                                                <Stack
                                                    direction="row"
                                                    justifyContent="flex-end"
                                                    spacing={1}
                                                >
                                                    <UseStateBoolean defaultValue={false}>
                                                        {(open, setOpen) => (
                                                            <UseStateValidationErrors defaultValue={[]}>
                                                                {(errors, setErrors) => (
                                                                    <React.Fragment>
                                                                        <Mutation
                                                                            mutation={UPDATE_TYPE}
                                                                            onError={(err: ApolloError) => {
                                                                                if (err.message === 'Bad Request Exception') {
                                                                                    setErrors(parseErrors(err));
                                                                                }
                                                                            }}
                                                                            onCompleted={() => {
                                                                                setOpen(false);
                                                                                setKey(uuidv4());
                                                                            }}
                                                                        >
                                                                            {(mutate: MutationFunction, { loading }: MutationResult) => (
                                                                                <TypeForm
                                                                                    open={open}
                                                                                    onClose={() => setOpen(false)}
                                                                                    initialValues={{
                                                                                        name,
                                                                                        code,
                                                                                        requiredDrops,
                                                                                        description,
                                                                                        placeIds: places.edges.map(({ id }: { id: string; }) => id)
                                                                                    }}
                                                                                    errors={errors}
                                                                                    onSubmit={values => {
                                                                                        setErrors([]);
                                                                                        mutate({
                                                                                            variables: Object.assign(values, {
                                                                                                id
                                                                                            })
                                                                                        });
                                                                                    }}
                                                                                    submitButtonProps={{
                                                                                        loading
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Mutation>
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                setErrors([]);
                                                                                setOpen(true);
                                                                            }}
                                                                        >
                                                                            <Edit />
                                                                        </IconButton>
                                                                    </React.Fragment>
                                                                )}
                                                            </UseStateValidationErrors>
                                                        )}
                                                    </UseStateBoolean>
                                                    <UseStateBoolean defaultValue={false}>
                                                        {(open, setOpen) => (
                                                            <Mutation
                                                                mutation={DELETE_TYPE}
                                                                variables={{
                                                                    id
                                                                }}
                                                                onError={(err: ApolloError) => {
                                                                    //
                                                                }}
                                                                onCompleted={() => {
                                                                    setOpen(false);
                                                                    setKey(uuidv4());
                                                                }}
                                                            >
                                                                {(mutate: MutationFunction, { loading }: MutationResult) => (
                                                                    <React.Fragment>
                                                                        <IconButton onClick={() => setOpen(true)}>
                                                                            <Trash />
                                                                        </IconButton>
                                                                        <Confirm
                                                                            open={open}
                                                                            onClose={() => setOpen(false)}
                                                                            title="Устгах"
                                                                            description={`Та "${name}" хамтрагч байгууллагыг устгахыг зөвшөөрч байна уу?`}
                                                                            okText="Тийм"
                                                                            cancelText="Үгүй"
                                                                            onOk={mutate}
                                                                            onCancel={() => setOpen(false)}
                                                                            okButtonProps={{
                                                                                loading
                                                                            }}
                                                                        />
                                                                    </React.Fragment>
                                                                )}
                                                            </Mutation>
                                                        )}
                                                    </UseStateBoolean>
                                                </Stack>
                                            </Box>
                                        </TableCell>
                                    )}
                                />
                            </DataTable>
                        </div>
                    )}
                </UseStateString>
            )}
        </UseStateString>
    );
};
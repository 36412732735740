export const setAccessToken = (accessToken: string | null) => ({
    type: 'SET_ACCESS_TOKEN',
    payload: {
        accessToken
    }
});

export const setUser = (user: any) => ({
    type: 'SET_USER',
    payload: {
        user
    }
});
import { Box, Card, Grid, Skeleton, Stack, Typography } from '@mui/material';

export const TreeSkeleton = (): JSX.Element => {
    return (
        <Card variant="outlined">
            <Box padding={2}>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                    spacing={1}
                >
                    <Skeleton
                        variant="text"
                        width={240}
                        sx={{
                            fontSize: 20,
                            lineHeight: '32px'
                        }}
                    />
                </Stack>
                <Grid
                    justifyContent="flex-end"
                    alignItems="center"
                    container
                    sx={{
                        marginY: 4
                    }}
                >
                    <Grid item xs={6}>
                        <Stack spacing={1}>
                            <Card variant="outlined">
                                <Box
                                    sx={{
                                        paddingY: '4px',
                                        paddingX: 2
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        Тарьж эхэлсэн
                                    </Typography>
                                    <Skeleton
                                        variant="text"
                                        width={100}
                                        sx={{
                                            fontSize: 16,
                                            lineHeight: '28px'
                                        }}
                                    />
                                </Box>
                            </Card>
                            <Card variant="outlined">
                                <Box
                                    sx={{
                                        paddingY: '4px',
                                        paddingX: 2
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        Усалсан
                                    </Typography>
                                    <Skeleton
                                        variant="text"
                                        width={100}
                                        sx={{
                                            fontSize: 16,
                                            lineHeight: '28px'
                                        }}
                                    />
                                </Box>
                            </Card>
                            <Card variant="outlined">
                                <Box
                                    sx={{
                                        paddingY: '4px',
                                        paddingX: 2
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        Услах дутуу
                                    </Typography>
                                    <Skeleton
                                        variant="text"
                                        width={100}
                                        sx={{
                                            fontSize: 16,
                                            lineHeight: '28px'
                                        }}
                                    />
                                </Box>
                            </Card>
                        </Stack>
                    </Grid>
                </Grid>
                <Stack
                    alignItems="center"
                    spacing={1}
                >
                    <Skeleton
                        variant="text"
                        width={100}
                        sx={{
                            fontSize: 14,
                            lineHeight: '20px'
                        }}
                    />
                    <Skeleton
                        variant="rounded"
                        width={180}
                        height={30}
                    />
                </Stack>
            </Box>
        </Card>
    );
};
import { AppBar as MuiAppBar, IconButton, styled, Toolbar } from '@mui/material';
import { Menu } from 'react-feather';
import { createUseStyles } from 'react-jss';
import { ThemeContext } from '../../contexts/theme-context';

const useStyles = createUseStyles({
    appBar: {
        boxShadow: 'none !important'
    }
});

const StyledAppBar = styled(MuiAppBar)(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        display: 'none'
    }
}));

export const AppBar = (): JSX.Element => {
    const {
        appBar
    } = useStyles();

    return (
        <ThemeContext.Consumer>
            {({ open, setOpen }) => (
                <StyledAppBar
                    position="fixed"
                    color="transparent"
                    classes={{
                        root: appBar
                    }}
                >
                    <Toolbar variant="dense">
                        <IconButton
                            aria-label="open drawer"
                            edge="start"
                            onClick={() => setOpen(!open)}
                            sx={{
                                color: '#000'
                            }}
                        >
                            <Menu />
                        </IconButton>
                    </Toolbar>
                </StyledAppBar>
            )}
        </ThemeContext.Consumer>
    );
};
import { ApolloError } from '@apollo/client';
import { ValidationError } from '../types';

const breakPoints = [
    {
        lvl: 1,
        value: 10
    },
    {
        lvl: 2,
        value: 20
    },
    {
        lvl: 3,
        value: 30
    },
    {
        lvl: 4,
        value: 40
    },
    {
        lvl: 5,
        value: 50
    },
    {
        lvl: 6,
        value: 60
    },
    {
        lvl: 7,
        value: 70
    },
    {
        lvl: 8,
        value: 80
    },
    {
        lvl: 9,
        value: 90
    },
    {
        lvl: 10,
        value: 100
    }
];

/**
 * Return message from validation error
 *
 * @param {any[]} errors
 * @param {string} prop
 * @returns {string|undefined}
 */
export const getValidationErrorMessage = (errors: ValidationError[], prop: string): string | undefined => {
    const props = prop.split('.');
    const err = props.reduce<ValidationError[]>((errors: ValidationError[], value: string) => value === props[props.length - 1] ? errors : errors.find(({ property }) => property === value)?.children || [], errors).find(({ property }) => property === props[props.length - 1]);
    return err?.constraints[Object.keys(err?.constraints)[0]];
};

/**
 * Return validation errors
 *
 * @param {ApolloError} err
 * @returns {ValidationError[]}
 */
export const parseErrors = (err: ApolloError): ValidationError[] => {
    return err.graphQLErrors.reduce((messages: ValidationError[], error: any) => [
        ...messages,
        ...error.extensions.response.message
    ], []);
};

/**
 * Return tree level from progress
 *
 * @param {number} progress
 * @returns {number}
 */
export const level = (progress: number): number => {
    return breakPoints.find(({ value }) => progress <= value)?.lvl || 10;
};

/**
 * Return tree level from progress
 *
 * @param {string} code
 * @param {number} progress
 * @returns {unknown}
 */
export const generateTreeJson = (code: 'aspen' | 'elm' | 'pine' | 'willow' | 'sea-buckthorn', progress: number): string => {
    return `${code}/${level(progress)}.json`;
};

import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, InputAdornment, TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Formik } from 'formik';
import { X } from 'react-feather';
import { getValidationErrorMessage } from '../lib/helpers';
import { ValidationError } from '../types';

type SubmitButtonProps = {
    loading: boolean;
};

type Values = {
    plantedAt: number | null;
};

type TreeFormProps = {
    open: boolean;
    onClose: () => void;
    onSubmit: (values: Values) => Promise<void> | void;
    initialValues: Values;
    errors: ValidationError[];
    submitButtonProps?: SubmitButtonProps;
};

export const TreeForm = (props: TreeFormProps): JSX.Element => {
    const {
        submitButtonProps = {},
        open,
        onClose,
        errors,
        ...otherProps
    } = props;

    return (
        <Dialog
            scroll="body"
            maxWidth="xs"
            open={open}
            fullWidth={true}
            onClose={(_, reason) => {
                reason === 'escapeKeyDown' && onClose();
            }}
        >
            <Formik {...otherProps}>
                {({ values, handleSubmit, getFieldHelpers }) => (
                    <form
                        onSubmit={handleSubmit}
                        autoComplete="off"
                        noValidate
                    >
                        <DialogTitle>
                            Мод
                        </DialogTitle>
                        <Divider />
                        <DialogContent
                            sx={{
                                paddingTop: '4px',
                                paddingBottom: '12px'
                            }}
                        >
                            <MobileDatePicker
                                label="Тарьсан огноо"
                                value={values.plantedAt}
                                onChange={value => getFieldHelpers('plantedAt').setValue(value)}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        error={errors.some(({ property }) => property === 'plantedAt')}
                                        helperText={getValidationErrorMessage(errors, 'plantedAt')}
                                        margin="normal"
                                        variant="standard"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            getFieldHelpers('plantedAt').setValue(null);
                                                        }}
                                                    >
                                                        <X />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        fullWidth
                                    />
                                )}
                            />
                        </DialogContent>
                        <DialogActions>
                            <LoadingButton
                                {...submitButtonProps}
                                variant="contained"
                                type="submit"
                                disableElevation
                            >
                                Хадгалах
                            </LoadingButton>
                            <Button onClick={onClose}>
                                Хаах
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
};
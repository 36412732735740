import { ApolloError, gql, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, MenuItem, TextField } from '@mui/material';
import { Formik } from 'formik';
import { getValidationErrorMessage } from '../lib/helpers';
import { ValidationError } from '../types';

const PLACES = gql`
    query Places {
        places {
            edges {
                id
                name
            }
        }
    }
`;

type SubmitButtonProps = {
    loading: boolean;
};

type Values = {
    name: string;
    code: string;
    requiredDrops: number | null;
    description: string;
    placeIds: string[];
};

type TypeFormProps = {
    open: boolean;
    onClose: () => void;
    onSubmit: (values: Values) => Promise<void> | void;
    initialValues: Values;
    errors: ValidationError[];
    submitButtonProps?: SubmitButtonProps;
};

export const TypeForm = (props: TypeFormProps): JSX.Element => {
    const {
        submitButtonProps = {},
        open,
        onClose,
        errors,
        ...otherProps
    } = props;

    return (
        <Dialog
            scroll="body"
            maxWidth="xs"
            open={open}
            fullWidth={true}
            onClose={(_, reason) => {
                reason === 'escapeKeyDown' && onClose();
            }}
        >
            <Formik {...otherProps}>
                {({ values, handleChange, handleSubmit }) => (
                    <form
                        onSubmit={handleSubmit}
                        autoComplete="off"
                        noValidate
                    >
                        <DialogTitle>
                            Модны төрөл
                        </DialogTitle>
                        <Divider />
                        <DialogContent
                            sx={{
                                paddingTop: '4px',
                                paddingBottom: '12px'
                            }}
                        >
                            <TextField
                                label="Нэр"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'name')}
                                helperText={getValidationErrorMessage(errors, 'name')}
                                margin="normal"
                                variant="standard"
                                fullWidth
                                required
                            />
                            <TextField
                                label="Код"
                                name="code"
                                value={values.code}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'code')}
                                helperText={getValidationErrorMessage(errors, 'code')}
                                margin="normal"
                                variant="standard"
                                fullWidth
                                required
                                select
                            >
                                <MenuItem value="aspen">
                                    aspen
                                </MenuItem>
                                <MenuItem value="elm">
                                    elm
                                </MenuItem>
                                <MenuItem value="pine">
                                    pine
                                </MenuItem>
                                <MenuItem value="willow">
                                    willow
                                </MenuItem>
                                <MenuItem value="sea-buckthorn">
                                    sea-buckthorn
                                </MenuItem>
                            </TextField>
                            <TextField
                                label="Нийт ус"
                                name="requiredDrops"
                                value={values.requiredDrops || ''}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'requiredDrops')}
                                helperText={getValidationErrorMessage(errors, 'requiredDrops')}
                                margin="normal"
                                variant="standard"
                                type="number"
                                inputProps={{
                                    inputMode: 'numeric',
                                    pattern: '[0-9]*'
                                }}
                                fullWidth
                                required
                            />
                            <TextField
                                label="Товч тайлбар"
                                name="description"
                                value={values.description}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'description')}
                                helperText={getValidationErrorMessage(errors, 'description')}
                                margin="normal"
                                variant="standard"
                                rows={4}
                                multiline
                                fullWidth
                                required
                            />
                            <Query
                                fetchPolicy="no-cache"
                                query={PLACES}
                                onError={(err: ApolloError) => {
                                    //
                                }}
                            >
                                {({ data, loading }: QueryResult) => (
                                    <TextField
                                        label="Байршил"
                                        name="placeIds"
                                        value={values.placeIds}
                                        onChange={handleChange}
                                        error={errors.some(({ property }) => property === 'placeIds')}
                                        helperText={getValidationErrorMessage(errors, 'placeIds')}
                                        margin="normal"
                                        variant="standard"
                                        children={data?.places.edges.map(({ id, name }: { id: string; name: string; }) => (
                                            <MenuItem
                                                key={id}
                                                value={id}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                        select={loading === false}
                                        SelectProps={{
                                            multiple: true
                                        }}
                                        InputProps={{
                                            readOnly: loading
                                        }}
                                        fullWidth
                                        required
                                    />
                                )}
                            </Query>
                        </DialogContent>
                        <DialogActions>
                            <LoadingButton
                                {...submitButtonProps}
                                variant="contained"
                                type="submit"
                                disableElevation
                            >
                                Хадгалах
                            </LoadingButton>
                            <Button onClick={onClose}>
                                Хаах
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
};
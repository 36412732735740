import { ApolloProvider } from '@apollo/client';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { apolloClient } from './plugins/apollo-client';
import { persistor, store } from './redux/configureStore';
import { Router } from './Router';
import { theme } from './theme/muiTheme';

export default function App() {
    return (
        <BrowserRouter>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ApolloProvider client={apolloClient}>
                        <SnackbarProvider
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <ThemeProvider theme={theme}>
                                    <CssBaseline />
                                    <Router />
                                </ThemeProvider>
                            </LocalizationProvider>
                        </SnackbarProvider>
                    </ApolloProvider>
                </PersistGate>
            </Provider>
        </BrowserRouter>
    );
};

import { ApolloError, gql, MutationFunction, MutationResult } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Link, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { LogIn } from 'react-feather';
import { connect } from 'react-redux';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import { createUseState } from '../components/common/UseState';
import { getValidationErrorMessage, parseErrors } from '../lib/helpers';
import { setAccessToken, setUser } from '../redux/auth/actions';
import { RootState } from '../redux/configureStore';
import { ValidationError } from '../types';

const UseState = createUseState<ValidationError[]>();

const SIGN_IN = gql`
    mutation Authenticate(
        $email: String
        $password: String
    ) {
        signIn(input: {
            email: $email
            password: $password
        }) {
            accessToken
            user {
                id
                admin
                email
            }
        }
    }
`;

type AuthenticateProps = {
    setAccessToken: (accessToken: string | null) => void;
    setUser: (user: any) => void;
    user: any;
};

const AuthenticateComponent = (props: AuthenticateProps): JSX.Element => {
    if (props.user) {
        return (
            <Navigate
                to="/"
                replace
            />
        );
    }

    return (
        <div>
            <Grid
                justifyContent="center"
                alignItems="center"
                container
                sx={{
                    padding: 2
                }}
            >
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={5}
                    xl={3}
                >
                    <Box
                        component="img"
                        src="/static/images/logo.png"
                        alt="ocademy logo"
                        sx={{
                            display: 'block',
                            height: 32,
                            margin: '70px auto 30px'
                        }}
                    />
                    <Typography
                        variant="h6"
                        color="text.secondary"
                        textAlign="center"
                        fontWeight={300}
                        sx={{
                            marginTop: 4,
                            marginBottom: 2
                        }}
                    >
                        Hello there! Sign in and start managing <br /> your app
                    </Typography>
                    <UseState defaultValue={[]}>
                        {(errors, setErrors) => (
                            <Mutation
                                mutation={SIGN_IN}
                                onError={(err: ApolloError) => {
                                    if (err.message === 'Bad Request Exception') {
                                        setErrors(parseErrors(err));
                                    }
                                }}
                                onCompleted={({ signIn }: any) => {
                                    props.setAccessToken(signIn.accessToken);
                                    props.setUser(signIn.user);
                                }}
                            >
                                {(mutate: MutationFunction, { loading }: MutationResult) => (
                                    <Formik
                                        initialValues={{
                                            email: '',
                                            password: ''
                                        }}
                                        onSubmit={values => {
                                            setErrors([]);
                                            mutate({
                                                variables: values
                                            });
                                        }}
                                    >
                                        {({ values, handleChange, handleSubmit }) => (
                                            <form
                                                onSubmit={handleSubmit}
                                                autoComplete="off"
                                                noValidate
                                            >
                                                <TextField
                                                    label="И-мэйл хаяг"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    error={errors.some(({ property }) => property === 'email')}
                                                    helperText={getValidationErrorMessage(errors, 'email')}
                                                    variant="outlined"
                                                    margin="normal"
                                                    autoComplete="username"
                                                    fullWidth
                                                    InputProps={{
                                                        sx: {
                                                            backgroundColor: '#fff'
                                                        }
                                                    }}
                                                />
                                                <TextField
                                                    label="Нууц үг"
                                                    name="password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    error={errors.some(({ property }) => property === 'password')}
                                                    helperText={getValidationErrorMessage(errors, 'password')}
                                                    type="password"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    autoComplete="current-password"
                                                    InputProps={{
                                                        sx: {
                                                            backgroundColor: '#fff'
                                                        }
                                                    }}
                                                />
                                                <Box textAlign="right">
                                                    <Link
                                                        component={RouterLink}
                                                        to="/forgot-password"
                                                        underline="none"
                                                        color="text.secondary"
                                                        sx={{
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        Нууц үгээ мартсан уу?
                                                    </Link>
                                                </Box>
                                                <Box textAlign="center">
                                                    <LoadingButton
                                                        size="large"
                                                        type="submit"
                                                        variant="contained"
                                                        loading={loading}
                                                        disableElevation
                                                        endIcon={
                                                            <LogIn />
                                                        }
                                                        sx={{
                                                            marginTop: 2
                                                        }}
                                                    >
                                                        Нэвтрэх
                                                    </LoadingButton>
                                                </Box>
                                            </form>
                                        )}
                                    </Formik>
                                )}
                            </Mutation>
                        )}
                    </UseState>
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    user: state.auth.user
});

const mapDispatchToProps = {
    setAccessToken,
    setUser
};

export const Authenticate = connect(mapStateToProps, mapDispatchToProps)(AuthenticateComponent);
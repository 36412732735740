import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type OkButtonProps = {
    loading: boolean;
};

type ConfirmProps = {
    open: boolean;
    onClose: () => void;
    title: string;
    description: string;
    okText: string;
    cancelText: string;
    onOk: () => void;
    onCancel: () => void;
    okButtonProps?: OkButtonProps;
};

export const Confirm = (props: ConfirmProps): JSX.Element => {
    const {
        open,
        onClose,
        title,
        description,
        okText,
        cancelText,
        onOk,
        onCancel,
        okButtonProps = {}
    } = props;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    {...okButtonProps}
                    variant="contained"
                    onClick={onOk}
                    disableElevation
                >
                    {okText}
                </LoadingButton>
                <Button onClick={onCancel}>
                    {cancelText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
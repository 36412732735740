import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField } from '@mui/material';
import { Formik } from 'formik';
import { DropZone } from '../components/common/DropZone';
import { getValidationErrorMessage } from '../lib/helpers';
import { ValidationError } from '../types';

type SubmitButtonProps = {
    loading: boolean;
};

type Values = {
    name: string;
    logo: string;
    certificate: string;
    description: string;
};

type PartnerFormProps = {
    open: boolean;
    onClose: () => void;
    onSubmit: (values: Values) => Promise<void> | void;
    initialValues: Values;
    errors: ValidationError[];
    submitButtonProps?: SubmitButtonProps;
};

export const PartnerForm = (props: PartnerFormProps): JSX.Element => {
    const {
        submitButtonProps = {},
        open,
        onClose,
        errors,
        ...otherProps
    } = props;

    return (
        <Dialog
            scroll="body"
            maxWidth="xs"
            open={open}
            fullWidth={true}
            onClose={(_, reason) => {
                reason === 'escapeKeyDown' && onClose();
            }}
        >
            <Formik {...otherProps}>
                {({ values, handleChange, handleSubmit, getFieldHelpers }) => (
                    <form
                        onSubmit={handleSubmit}
                        autoComplete="off"
                        noValidate
                    >
                        <DialogTitle>
                            Мод тарих байгууллага
                        </DialogTitle>
                        <Divider />
                        <DialogContent
                            sx={{
                                paddingTop: '4px',
                                paddingBottom: '12px'
                            }}
                        >
                            <TextField
                                label="Нэр"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'name')}
                                helperText={getValidationErrorMessage(errors, 'name')}
                                margin="normal"
                                variant="standard"
                                fullWidth
                                required
                            />
                            <TextField
                                label="Товч мэдээлэл"
                                name="description"
                                value={values.description}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'description')}
                                helperText={getValidationErrorMessage(errors, 'description')}
                                margin="normal"
                                variant="standard"
                                rows={4}
                                multiline
                                fullWidth
                                required
                            />
                            <DropZone
                                description="Энд дарж лого хуулна уу"
                                url="/files/upload-image"
                                error={errors.some(({ property }) => property === 'logo')}
                                value={values.logo}
                                onChange={value => getFieldHelpers('logo').setValue(value)}
                            />
                            <DropZone
                                description="Энд дарж сертификатны загвар хуулна уу"
                                url="/files/upload-image"
                                error={errors.some(({ property }) => property === 'certificate')}
                                value={values.certificate}
                                onChange={value => getFieldHelpers('certificate').setValue(value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <LoadingButton
                                {...submitButtonProps}
                                variant="contained"
                                type="submit"
                                disableElevation
                            >
                                Хадгалах
                            </LoadingButton>
                            <Button onClick={onClose}>
                                Хаах
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
};
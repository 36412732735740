import { ApolloError, gql, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { LoadingButton } from '@mui/lab';
import { Alert, Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, InputAdornment, MenuItem, TextField } from '@mui/material';
import { Formik } from 'formik';
import { debounce } from 'lodash';
import { useState } from 'react';
import { AlertCircle } from 'react-feather';
import { useTimer } from '../hooks/useTimer';
import { getValidationErrorMessage } from '../lib/helpers';
import { ValidationError } from '../types';

const USERS = gql`
    query Users($search: String) {
        users(
            search: $search
            take: 30
            sortBy: "nickname"
            sortOrder: "asc"
            where: {
                admin: false
            }
        ) {
            edges {
                id
                phone
                avatar
                nickname
            }
        }
    }
`;

type User = {
    id: string;
    phone: string;
    avatar: string | null;
    nickname: string | null;
};

type SubmitButtonProps = {
    loading: boolean;
};

type Values = {
    drops: number | null;
    users: User[];
};

type CreateRewardsFormProps = {
    open: boolean;
    onClose: () => void;
    onSubmit: (values: Values) => Promise<void> | void;
    initialValues: Values;
    errors: ValidationError[];
    submitButtonProps?: SubmitButtonProps;
};

export const CreateRewardsForm = (props: CreateRewardsFormProps): JSX.Element => {
    const {
        submitButtonProps = {
            loading: false
        },
        open,
        onClose,
        errors,
        ...otherProps
    } = props;

    const [
        count,
        countdown,
        cancel
    ] = useTimer({
        time: 7 // seconds
    });
    const [
        search,
        setSearch
    ] = useState('');

    return (
        <Dialog
            scroll="body"
            maxWidth="xs"
            open={open}
            fullWidth={true}
            onClose={(_, reason) => {
                reason === 'escapeKeyDown' && onClose();
            }}
        >
            <Formik {...otherProps}>
                {({ values, handleChange, handleSubmit, getFieldHelpers, submitForm }) => (
                    <form
                        onSubmit={handleSubmit}
                        autoComplete="off"
                        noValidate
                    >
                        <DialogTitle>
                            Ус бэлэглэх
                        </DialogTitle>
                        <Divider />
                        <DialogContent
                            sx={{
                                paddingTop: '4px',
                                paddingBottom: '12px'
                            }}
                        >
                            <TextField
                                label="Бэлэглэх усны хэмжээ"
                                name="drops"
                                value={values.drops || ''}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'drops')}
                                helperText={getValidationErrorMessage(errors, 'drops')}
                                margin="normal"
                                variant="standard"
                                type="number"
                                inputProps={{
                                    inputMode: 'numeric',
                                    pattern: '[0-9]*'
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            литр
                                        </InputAdornment>
                                    )
                                }}
                                required
                                fullWidth
                            />
                            <Query
                                fetchPolicy="no-cache"
                                query={USERS}
                                variables={{
                                    search
                                }}
                                onError={(err: ApolloError) => {
                                    //
                                }}
                            >
                                {({ data, loading }: QueryResult) => (
                                    <Autocomplete
                                        options={(data?.users.edges || []) as User[]}
                                        getOptionLabel={user => user.phone}
                                        value={values.users}
                                        onChange={(_, value) => {
                                            getFieldHelpers('users').setValue(value);
                                        }}
                                        onInputChange={debounce((e, value: string) => {
                                            setSearch(value);
                                        }, 500)}
                                        filterOptions={(x) => x}
                                        loading={loading}
                                        loadingText="Уншиж байна..."
                                        noOptionsText="Хэрэглэгч олдсонгүй"
                                        fullWidth
                                        multiple
                                        ChipProps={{
                                            sx: {
                                                borderRadius: 1
                                            }
                                        }}
                                        renderOption={(props, user) => (
                                            <MenuItem {...props}>
                                                {user.phone}
                                            </MenuItem>
                                        )}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label="Хэрэглэгч"
                                                error={errors.some(({ property }) => property === 'userIds')}
                                                helperText={getValidationErrorMessage(errors, 'userIds')}
                                                margin="normal"
                                                variant="standard"
                                            />
                                        )}
                                    />
                                )}
                            </Query>
                            <Alert
                                severity="error"
                                icon={
                                    <AlertCircle fontSize="inherit" />
                                }
                                sx={{
                                    marginTop: 1
                                }}
                            >
                                Бүх хэрэглэгчид ус бэлэглэх бол "Хэрэглэгч" талбарыг хоосон үлдээнэ үү
                            </Alert>
                        </DialogContent>
                        <DialogActions>
                            {count > 0 && (
                                <Button
                                    variant="outlined"
                                    disableElevation
                                    onClick={() => {
                                        cancel();
                                    }}
                                >
                                    Цуцлах ({count}s)
                                </Button>
                            )}
                            <LoadingButton
                                loading={submitButtonProps.loading || count > 0}
                                disabled={Boolean(values.drops) === false}
                                variant="contained"
                                disableElevation
                                onClick={() => {
                                    countdown(submitForm);
                                }}
                            >
                                Илгээх
                            </LoadingButton>
                            <Button onClick={onClose}>
                                Хаах
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
};
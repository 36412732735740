import { ApolloError, gql, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Avatar, Box, CircularProgress, Divider, Grid, Pagination, PaginationItem, Skeleton, Stack, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useParams } from 'react-router-dom';
import { Transaction } from '../components/Transaction';
import { Tree } from '../components/Tree';
import { TreeSkeleton } from '../components/TreeSkeleton';

const USER = gql`
    query User($id: String!) {
        user(id: $id) {
            phone
            drops
            avatar
            nickname
            trees(
                sortBy: "createdAt"
                sortOrder: "desc"
            ) {
                edges {
                    id
                    drops  
                    requiredDrops
                    progress
                    createdAt
                    type {
                        name
                        code
                    }
                    place {
                        name
                        partner {
                            name
                        }
                    }
                    certificate {
                        number
                    }
                }
            }
            unclaimedRewards {
                edges {
                    id
                    reason
                    drops
                    expireAt
                    gift {
                        sender {
                            avatar
                            nickname
                        }
                    }
                }
            }
        }
    }
`;

const TRANSACTIONS = gql`
    query Transactions(
        $id: String!
        $skip: Int
        $take: Int
        $sortBy: String
        $sortOrder: String
    ) {
        transactionsByUser(
            id: $id
            skip: $skip
            take: $take
            sortBy: $sortBy
            sortOrder: $sortOrder
        ) {
            count
            edges {
                id
                type
                method
                drops
                balance
                createdAt
                gift {
                    sender {
                        phone
                    }
                    receiver {
                        phone
                    }
                }
            }
        }
    }
`;

export const User = (): JSX.Element => {
    const { id } = useParams();

    const [
        perPage
    ] = useState(20);
    const [
        page,
        setPage
    ] = useState(1);
    const [
        phone,
        setPhone
    ] = useState('');
    const [
        drops,
        setDrops
    ] = useState(0);
    const [
        avatar,
        setAvatar
    ] = useState('');
    const [
        nickname,
        setNickname
    ] = useState('');
    const [
        trees,
        setTrees
    ] = useState<any[]>([]);

    const methodName = (method: string) => {
        switch (method) {
            case 'payon':
                return 'Худалдаж авсан';
            case 'loyalty':
                return 'Оноо шилжүүлсэн';
            case 'steal':
                return 'Хулгайлсан';
            case 'walk':
                return 'Алхалт';
            case 'watering':
                return 'Мод усалсан';
            case 'gift':
                return 'Бэлэг';
            case 'system':
                return 'Урамшуулал';
        }
    };

    return (
        <div>
            <Query
                fetchPolicy="no-cache"
                query={USER}
                variables={{
                    id
                }}
                onError={(err: ApolloError) => {
                    //
                }}
                onCompleted={({ user }: any) => {
                    const {
                        phone,
                        drops,
                        avatar,
                        nickname
                    } = user;
                    setPhone(phone);
                    setDrops(drops);
                    setAvatar(avatar);
                    setNickname(nickname);
                    setTrees(user.trees.edges);
                }}
            >
                {({ loading }: QueryResult) => (
                    <Fragment>
                        <Stack
                            alignItems="center"
                            direction="row"
                            spacing={2}
                        >
                            {loading
                                ?
                                <Skeleton
                                    variant="circular"
                                    width={80}
                                    height={80}
                                />
                                :
                                <Avatar
                                    src={`/static/images/avatars/${avatar}.png`}
                                    sx={{
                                        width: 80,
                                        height: 80,
                                        backgroundColor: '#f0f0f0'
                                    }}
                                />
                            }
                            {loading
                                ?
                                <div>
                                    <Skeleton
                                        variant="text"
                                        width={160}
                                        sx={{
                                            fontSize: 34,
                                            lineHeight: 1
                                        }}
                                    />
                                    <Skeleton
                                        variant="text"
                                        width={100}
                                        sx={{
                                            fontSize: 20
                                        }}
                                    />
                                </div>
                                :
                                <div>
                                    <Typography
                                        variant="h4"
                                        lineHeight={1}
                                    >
                                        {nickname}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        color="text.disabled"
                                        sx={{
                                            fontSize: 20
                                        }}
                                    >
                                        {drops} литр
                                    </Typography>
                                </div>
                            }
                        </Stack>
                        {loading
                            ?
                            <div>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        marginTop: 4
                                    }}
                                >
                                    Моднууд
                                </Typography>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    {[0, 1, 2, 3].map(key => (
                                        <Grid
                                            key={key}
                                            xs={12}
                                            sm={6}
                                            lg={4}
                                            xl={3}
                                            item
                                        >
                                            <TreeSkeleton />
                                        </Grid>

                                    ))}
                                </Grid>
                            </div>
                            :
                            <div>
                                {Boolean(trees.length) && (
                                    <div>
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                marginTop: 8,
                                                marginBottom: 2
                                            }}
                                        >
                                            Моднууд
                                        </Typography>
                                        <Grid
                                            container
                                            spacing={2}
                                        >
                                            {trees.map(({ id, drops, requiredDrops, progress, createdAt, type, place, certificate }) => (
                                                <Grid
                                                    key={id}
                                                    xs={12}
                                                    sm={6}
                                                    lg={4}
                                                    xl={3}
                                                    item
                                                >
                                                    <Tree
                                                        id={id}
                                                        drops={drops}
                                                        requiredDrops={requiredDrops}
                                                        progress={progress}
                                                        createdAt={createdAt}
                                                        typeName={type.name}
                                                        typeCode={type.code}
                                                        placeName={place.name}
                                                        certificateNumber={certificate?.number}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                )}
                            </div>
                        }
                        <Grid
                            container
                            spacing={2}
                            sx={{
                                marginTop: 8
                            }}
                        >
                            <Grid
                                xs={12}
                                sm={6}
                                lg={4}
                                xl={3}
                                item
                            >
                                <Typography
                                    variant="h5"
                                    sx={{
                                        marginBottom: 2
                                    }}
                                >
                                    Усны түүх
                                </Typography>
                                <Query
                                    fetchPolicy="no-cache"
                                    query={TRANSACTIONS}
                                    variables={{
                                        id,
                                        skip: perPage * (page - 1),
                                        take: perPage
                                    }}
                                    onError={(err: ApolloError) => {
                                        //
                                    }}
                                >
                                    {({ data, loading }: QueryResult) => (
                                        <div>
                                            {data?.transactionsByUser.edges.map(({ id, type, method, drops, balance, createdAt, gift }: any, index: number) => (
                                                <div key={id}>
                                                    <Transaction
                                                        type={type}
                                                        method={method}
                                                        drops={drops}
                                                        balance={balance}
                                                        createdAt={createdAt}
                                                        gift={gift}
                                                    />
                                                    {index !== data?.transactionsByUser.edges.length - 1 && (
                                                        <Divider />
                                                    )}
                                                </div>
                                            ))}
                                            {data && (
                                                <Box marginTop={2}>
                                                    <Box textAlign="center">
                                                        <Pagination
                                                            count={Math.ceil(data.transactionsByUser.count / perPage)}
                                                            page={page}
                                                            onChange={(e, value) => setPage(value)}
                                                            color="primary"
                                                            sx={{
                                                                display: 'inline-block'
                                                            }}
                                                            renderItem={item => (
                                                                <PaginationItem
                                                                    {...item}
                                                                    components={{
                                                                        previous: () => <ChevronLeft size={18} />,
                                                                        next: () => <ChevronRight size={18} />
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </Box>
                                                </Box>
                                            )}
                                            {loading && (
                                                <Box padding={2}>
                                                    <Box textAlign="center">
                                                        <CircularProgress />
                                                    </Box>
                                                </Box>
                                            )}
                                        </div>
                                    )}
                                </Query>
                            </Grid>
                        </Grid>
                    </Fragment>
                )}
            </Query>
        </div>
    );
};
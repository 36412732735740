import { ApolloError, MutationFunction, MutationResult, gql } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { Box, Button, IconButton, Stack, TableCell, TextField, Typography, styled } from '@mui/material';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import { default as React } from 'react';
import { Plus, Trash } from 'react-feather';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Confirm } from '../components/common/Confirm';
import { DataTable, DataTableColumn } from '../components/common/DataTable';
import { createUseState } from '../components/common/UseState';
import { UserForm } from '../forms/User';
import { parseErrors } from '../lib/helpers';
import { RootState } from '../redux/configureStore';
import { ValidationError } from '../types';

const UseStateString = createUseState<string>();
const UseStateBoolean = createUseState<boolean>();
const UseStateValidationErrors = createUseState<ValidationError[]>();

const USERS = gql`
    query Admins(
        $search: String
        $skip: Int
        $take: Int
        $sortBy: String
        $sortOrder: String
    ) {
        users(
            search: $search
            skip: $skip
            take: $take
            sortBy: $sortBy
            sortOrder: $sortOrder
            where: {
                admin: true
            }
        ) {
            count
            edges {
                id
                email
                createdAt
            }
        }
    }
`;

const CREATE_ADMIN = gql`
    mutation CreateAdmin(
        $email: String
        $password: String
        $passwordConfirmation: String
    ) {
        createAdmin(input: {
            email: $email
            password: $password
            passwordConfirmation: $passwordConfirmation
        }) {
            id
        }
    }
`;

const DELETE_USER = gql`
    mutation DeleteUser($id: String!) {
        deleteUser(id: $id) {
            id
        }
    }
`;

type AdminsProps = {
    user: any;
};

const StyledButton = styled(Button)(() => ({
    lineHeight: '24px'
}));

const mapStateToProps = (state: RootState) => ({
    user: state.auth.user
});

export const Admins = connect(mapStateToProps)(({ user }: AdminsProps): JSX.Element => {
    return (
        <UseStateString defaultValue={uuidv4()}>
            {(key, setKey) => (
                <UseStateString defaultValue="">
                    {(search, setSearch) => (
                        <div>
                            <Typography variant="h4">
                                Админ
                            </Typography>
                            <Box marginY={2}>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <UseStateBoolean defaultValue={false}>
                                        {(open, setOpen) => (
                                            <UseStateValidationErrors defaultValue={[]}>
                                                {(errors, setErrors) => (
                                                    <React.Fragment>
                                                        <Mutation
                                                            mutation={CREATE_ADMIN}
                                                            onError={(err: ApolloError) => {
                                                                if (err.message === 'Bad Request Exception') {
                                                                    setErrors(parseErrors(err));
                                                                }
                                                            }}
                                                            onCompleted={() => {
                                                                setOpen(false);
                                                                setKey(uuidv4());
                                                            }}
                                                        >
                                                            {(mutate: MutationFunction, { loading }: MutationResult) => (
                                                                <UserForm
                                                                    open={open}
                                                                    onClose={() => setOpen(false)}
                                                                    initialValues={{
                                                                        email: '',
                                                                        password: '',
                                                                        passwordConfirmation: ''
                                                                    }}
                                                                    errors={errors}
                                                                    onSubmit={values => {
                                                                        setErrors([]);
                                                                        mutate({
                                                                            variables: values
                                                                        });
                                                                    }}
                                                                    submitButtonProps={{
                                                                        loading
                                                                    }}
                                                                />
                                                            )}
                                                        </Mutation>
                                                        <StyledButton
                                                            variant="contained"
                                                            size="large"
                                                            disableElevation
                                                            startIcon={
                                                                <Plus />
                                                            }
                                                            onClick={() => {
                                                                setErrors([]);
                                                                setOpen(true);
                                                            }}
                                                        >
                                                            Шинэ
                                                        </StyledButton>
                                                    </React.Fragment>
                                                )}
                                            </UseStateValidationErrors>
                                        )}
                                    </UseStateBoolean>
                                    <TextField
                                        label="Хайх"
                                        size="small"
                                        variant="outlined"
                                        onChange={debounce(e => {
                                            setSearch(e.target.value);
                                        }, 500)}
                                        sx={{
                                            minWidth: 300
                                        }}
                                        InputProps={{
                                            sx: {
                                                backgroundColor: '#fff'
                                            }
                                        }}
                                    />
                                </Stack>
                            </Box>
                            <DataTable
                                key={key}
                                query={USERS}
                                dataPath="users"
                                variables={{
                                    search
                                }}
                            >
                                <DataTableColumn
                                    title="И-мэйл хаяг"
                                    dataIndex="email"
                                    align="left"
                                    sorter
                                />
                                <DataTableColumn
                                    title="Огноо"
                                    dataIndex="createdAt"
                                    align="left"
                                    sorter
                                    render={({ createdAt }) => (
                                        <TableCell padding="normal">
                                            {format(createdAt, 'yyyy-MM-dd HH:mm:ss')}
                                        </TableCell>
                                    )}
                                />
                                <DataTableColumn
                                    title=""
                                    dataIndex="id"
                                    align="right"
                                    render={({ id, email }) => (
                                        <TableCell padding="none">
                                            <Box paddingX={2}>
                                                <UseStateBoolean defaultValue={false}>
                                                    {(open, setOpen) => (
                                                        <Mutation
                                                            mutation={DELETE_USER}
                                                            variables={{
                                                                id
                                                            }}
                                                            onError={(err: ApolloError) => {
                                                                //
                                                            }}
                                                            onCompleted={() => {
                                                                setOpen(false);
                                                                setKey(uuidv4());
                                                            }}
                                                        >
                                                            {(mutate: MutationFunction, { loading }: MutationResult) => (
                                                                <React.Fragment>
                                                                    <IconButton
                                                                        disabled={id === user.id}
                                                                        onClick={() => {
                                                                            setOpen(true);
                                                                        }}
                                                                    >
                                                                        <Trash />
                                                                    </IconButton>
                                                                    <Confirm
                                                                        open={open}
                                                                        onClose={() => setOpen(false)}
                                                                        title="Устгах"
                                                                        description={`Та "${email}" админыг устгахыг зөвшөөрч байна уу?`}
                                                                        okText="Тийм"
                                                                        cancelText="Үгүй"
                                                                        onOk={mutate}
                                                                        onCancel={() => setOpen(false)}
                                                                        okButtonProps={{
                                                                            loading
                                                                        }}
                                                                    />
                                                                </React.Fragment>
                                                            )}
                                                        </Mutation>
                                                    )}
                                                </UseStateBoolean>
                                            </Box>
                                        </TableCell>
                                    )}
                                />
                            </DataTable>
                        </div>
                    )}
                </UseStateString>
            )}
        </UseStateString>
    );
});
import { ApolloError, gql, MutationFunction, MutationResult, QueryResult } from '@apollo/client';
import { Mutation, Query } from '@apollo/client/react/components';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, InputAdornment, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { createUseState } from '../components/common/UseState';
import { getValidationErrorMessage, parseErrors } from '../lib/helpers';
import { ValidationError } from '../types';

type Code = 'MAX_DAILY_DROPS_OF_STEPS_REWARD' | 'MAX_DAILY_DROPS_OF_GIFT' | 'MAX_DROPS_OF_EACH_GIFT' | 'MAX_DROPS_OF_EACH_CONVERSION';

const availableOptions: { name: string; code: Code; }[] = [
    {
        name: 'Өдөрт алхалтаас авах дээд',
        code: 'MAX_DAILY_DROPS_OF_STEPS_REWARD'
    },
    {
        name: 'Өдөрт бэлэглэх дээд',
        code: 'MAX_DAILY_DROPS_OF_GIFT'
    },
    {
        name: 'Нэг удаад бэлэглэх дээд',
        code: 'MAX_DROPS_OF_EACH_GIFT'
    },
    {
        name: 'Нэг удаад оноогоор авах дээд',
        code: 'MAX_DROPS_OF_EACH_CONVERSION'
    }
];

const UseState = createUseState<ValidationError[]>();

const OPTIONS = gql`
    query Options {
        options {
            edges {
                code
                value
            }
        }
    }
`;

const UPDATE_OPTIONS = gql`
    mutation UpdateOptions(
        $MAX_DAILY_DROPS_OF_STEPS_REWARD: Int
        $MAX_DAILY_DROPS_OF_GIFT: Int
        $MAX_DROPS_OF_EACH_GIFT: Int
        $MAX_DROPS_OF_EACH_CONVERSION: Int
    ) {
        updateOptions(input: {
            MAX_DAILY_DROPS_OF_STEPS_REWARD: $MAX_DAILY_DROPS_OF_STEPS_REWARD
            MAX_DAILY_DROPS_OF_GIFT: $MAX_DAILY_DROPS_OF_GIFT
            MAX_DROPS_OF_EACH_GIFT: $MAX_DROPS_OF_EACH_GIFT
            MAX_DROPS_OF_EACH_CONVERSION: $MAX_DROPS_OF_EACH_CONVERSION
        })
    }
`;

export const Options = (): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar();

    return (
        <Grid
            justifyContent="center"
            container
        >
            <Grid
                xs={12}
                sm={8}
                lg={5}
                xl={4}
                item
            >
                <Typography
                    variant="h4"
                    textAlign="center"
                >
                    Тохиргоо
                </Typography>
                <UseState defaultValue={[]}>
                    {(errors, setErrors) => (
                        <Mutation
                            mutation={UPDATE_OPTIONS}
                            onError={(err: ApolloError) => {
                                if (err.message === 'Bad Request Exception') {
                                    setErrors(parseErrors(err));
                                }
                            }}
                            onCompleted={() => {
                                enqueueSnackbar('Тохиргоо шинэчлэгдлээ', {
                                    autoHideDuration: 4000,
                                    variant: 'success'
                                });
                            }}
                        >
                            {(mutate: MutationFunction, { loading }: MutationResult) => (
                                <Formik
                                    initialValues={{
                                        MAX_DAILY_DROPS_OF_STEPS_REWARD: null,
                                        MAX_DAILY_DROPS_OF_GIFT: null,
                                        MAX_DROPS_OF_EACH_GIFT: null,
                                        MAX_DROPS_OF_EACH_CONVERSION: null
                                    }}
                                    onSubmit={values => {
                                        setErrors([]);
                                        mutate({
                                            variables: values
                                        });
                                    }}
                                >
                                    {({ values, handleChange, handleSubmit, getFieldHelpers }) => (
                                        <Query
                                            fetchPolicy="no-cache"
                                            query={OPTIONS}
                                            onError={(err: ApolloError) => {
                                                //
                                            }}
                                            onCompleted={({ options }: any) => {
                                                options.edges.map(({ code, value }: { code: Code; value: string; }) => getFieldHelpers(code).setValue(parseInt(value)));
                                            }}
                                        >
                                            {({ loading: fetching }: QueryResult) => (
                                                <form
                                                    onSubmit={handleSubmit}
                                                    autoComplete="off"
                                                    noValidate
                                                >
                                                    <Box marginY={4}>
                                                        <Stack spacing={1}>
                                                            {availableOptions.map(({ name, code }) => (
                                                                <div key={code}>
                                                                    <Grid
                                                                        alignItems="center"
                                                                        columnSpacing={2}
                                                                        container
                                                                    >
                                                                        <Grid
                                                                            xs={6}
                                                                            item
                                                                        >
                                                                            <Typography
                                                                                color="text.secondary"
                                                                                textAlign="right"
                                                                            >
                                                                                {name}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid
                                                                            xs={6}
                                                                            item
                                                                        >
                                                                            {fetching
                                                                                ?
                                                                                <Skeleton
                                                                                    variant="rounded"
                                                                                    width="100%"
                                                                                    height={56}
                                                                                />
                                                                                :
                                                                                <TextField
                                                                                    name={code}
                                                                                    value={values[code] || ''}
                                                                                    onChange={handleChange}
                                                                                    error={errors.some(({ property }) => property === code)}
                                                                                    helperText={getValidationErrorMessage(errors, code)}
                                                                                    variant="outlined"
                                                                                    margin="none"
                                                                                    type="number"
                                                                                    inputProps={{
                                                                                        inputMode: 'numeric',
                                                                                        pattern: '[0-9]*'
                                                                                    }}
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment position="end">
                                                                                                литр
                                                                                            </InputAdornment>
                                                                                        )
                                                                                    }}
                                                                                    fullWidth
                                                                                />
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            ))}
                                                        </Stack>
                                                    </Box>
                                                    <Box textAlign="center">
                                                        <LoadingButton
                                                            loading={fetching || loading}
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            size="large"
                                                            disableElevation
                                                        >
                                                            Хадгалах
                                                        </LoadingButton>
                                                    </Box>
                                                </form>
                                            )}
                                        </Query>
                                    )}
                                </Formik>
                            )}
                        </Mutation>
                    )}
                </UseState>
            </Grid>
        </Grid>
    );
};
import { useEffect, useState } from 'react';

type UseTimerProps = {
    time: number;
};

type UseTimer<P> = {
    (props: P): [
        number,
        (callback: (...args: any[]) => any) => Promise<any>,
        () => void
    ];
};

export const useTimer: UseTimer<UseTimerProps> = ({ time }: UseTimerProps) => {
    const [
        count,
        setCount
    ] = useState(0);
    const [
        timeoutId,
        setTimeoutId
    ] = useState<NodeJS.Timer>();
    const [
        intervalId,
        setIntervalId
    ] = useState<NodeJS.Timer>();

    useEffect(() => {
        if (count === 0) {
            clearInterval(intervalId);
        }
    }, [count]);

    const countdown = async (callback: (...args: any[]) => any): Promise<any> => {
        new Promise((resolve, reject) => {
            setCount(time);
            setIntervalId(setInterval(() => {
                setCount(count => count - 1);
            }, 1000));
            setTimeoutId(setTimeout(async () => {
                try {
                    resolve(await callback());
                } catch (err) {
                    reject(err);
                }
            }, time * 1000));
        });
    };

    const cancel = () => {
        clearTimeout(timeoutId);
        setCount(0);
    };

    return [
        count,
        countdown,
        cancel
    ];
};

import { Box, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';

type TransactionProps = {
    type: 'deposit' | 'withdraw';
    method: string;
    drops: number;
    balance: number;
    createdAt: number;
    gift?: {
        sender: {
            phone: string;
        };
        receiver: {
            phone: string;
        };
    };
};

export const Transaction = (props: TransactionProps): JSX.Element => {
    const {
        type,
        method,
        drops,
        balance,
        createdAt,
        gift
    } = props;

    const methodName = () => {
        switch (method) {
            case 'payon':
                return 'Худалдаж авсан';
            case 'loyalty':
                return 'Оноо шилжүүлсэн';
            case 'steal':
                return 'Хулгайлсан';
            case 'walk':
                return 'Алхалт';
            case 'watering':
                return 'Мод усалсан';
            case 'gift':
                return `Бэлэг (${type === 'deposit' ? gift?.sender.phone : gift?.receiver.phone})`;
            case 'system':
                return 'Урамшуулал';
        }
    };

    return (
        <Box
            sx={{
                paddingY: '12px'
            }}
        >
            <Grid
                columnSpacing={1}
                alignItems="center"
                container
            >
                <Grid xs item>
                    <Typography variant="subtitle1">
                        {methodName()}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.disabled"
                        fontSize={12}
                    >
                        {format(new Date(createdAt), 'yyyy-MM-dd HH:mm:ss')}
                    </Typography>
                </Grid>
                <Grid item>
                    {type === 'deposit' && (
                        <Typography
                            variant="h6"
                            color="success.main"
                            textAlign="right"
                            lineHeight={1}
                        >
                            +{drops}л
                        </Typography>
                    )}
                    {type === 'withdraw' && (
                        <Typography
                            variant="h6"
                            color="error.main"
                            textAlign="right"
                            lineHeight={1}
                        >
                            -{drops}л
                        </Typography>
                    )}
                    <Typography
                        variant="body2"
                        color="text.disabled"
                        fontSize={12}
                        textAlign="right"
                    >
                        Үлдэгдэл: {balance}л
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};
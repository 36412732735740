import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { setAccessToken, setUser } from '../redux/auth/actions';
import { store } from '../redux/configureStore';

const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`
});

const authLink = setContext((_, { headers }) => {
    const { accessToken } = store.getState().auth;
    return {
        headers: Object.assign({}, headers, {
            authorization: accessToken ? `Bearer ${accessToken}` : ''
        })
    };
});

const errorLink = onError(error => {
    if (error?.graphQLErrors?.some((err: any) => err.extensions.code === 'UNAUTHENTICATED')) {
        store.dispatch(setAccessToken(null));
        store.dispatch(setUser(null));
    }
});

export const apolloClient = new ApolloClient({
    link: from([
        errorLink,
        authLink,
        httpLink
    ]),
    cache: new InMemoryCache({
        addTypename: false
    })
});
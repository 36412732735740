import { ApolloError, gql, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, MenuItem, TextField } from '@mui/material';
import { Formik } from 'formik';
import { getValidationErrorMessage } from '../lib/helpers';
import { ValidationError } from '../types';

const PARTNERS = gql`
    query Partners {
        partners {
            edges {
                id
                name
            }
        }
    }
`;

type SubmitButtonProps = {
    loading: boolean;
};

type Values = {
    name: string;
    projectName: string;
    projectDescription: string;
    location: string;
    partnerId: string;
};

type PlaceFormProps = {
    open: boolean;
    onClose: () => void;
    onSubmit: (values: Values) => Promise<void> | void;
    initialValues: Values;
    errors: ValidationError[];
    submitButtonProps?: SubmitButtonProps;
};

export const PlaceForm = (props: PlaceFormProps): JSX.Element => {
    const {
        submitButtonProps = {},
        open,
        onClose,
        errors,
        ...otherProps
    } = props;

    return (
        <Dialog
            scroll="body"
            maxWidth="xs"
            open={open}
            fullWidth={true}
            onClose={(_, reason) => {
                reason === 'escapeKeyDown' && onClose();
            }}
        >
            <Formik {...otherProps}>
                {({ values, handleChange, handleSubmit, getFieldHelpers }) => (
                    <form
                        onSubmit={handleSubmit}
                        autoComplete="off"
                        noValidate
                    >
                        <DialogTitle>
                            Байршил
                        </DialogTitle>
                        <Divider />
                        <DialogContent
                            sx={{
                                paddingTop: '4px',
                                paddingBottom: '12px'
                            }}
                        >
                            <TextField
                                label="Нэр"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'name')}
                                helperText={getValidationErrorMessage(errors, 'name')}
                                margin="normal"
                                variant="standard"
                                fullWidth
                                required
                            />
                            <TextField
                                label="Төслийн нэр"
                                name="projectName"
                                value={values.projectName}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'projectName')}
                                helperText={getValidationErrorMessage(errors, 'projectName')}
                                margin="normal"
                                variant="standard"
                                fullWidth
                                required
                            />
                            <TextField
                                label="Төслийн товч мэдээлэл"
                                name="projectDescription"
                                value={values.projectDescription}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'projectDescription')}
                                helperText={getValidationErrorMessage(errors, 'projectDescription')}
                                margin="normal"
                                variant="standard"
                                rows={4}
                                multiline
                                fullWidth
                                required
                            />
                            <TextField
                                label="Координат"
                                placeholder="latitude,longitude"
                                name="location"
                                value={values.location}
                                onChange={handleChange}
                                error={errors.some(({ property }) => property === 'location')}
                                helperText={getValidationErrorMessage(errors, 'location')}
                                margin="normal"
                                variant="standard"
                                fullWidth
                                required
                            />
                            <Query
                                fetchPolicy="no-cache"
                                query={PARTNERS}
                                onError={(err: ApolloError) => {
                                    //
                                }}
                            >
                                {({ data, loading }: QueryResult) => (
                                    <TextField
                                        label="Хамтрагч байгууллага"
                                        name="partnerId"
                                        value={values.partnerId}
                                        onChange={handleChange}
                                        error={errors.some(({ property }) => property === 'partnerId')}
                                        helperText={getValidationErrorMessage(errors, 'partnerId')}
                                        margin="normal"
                                        variant="standard"
                                        children={data?.partners.edges.map(({ id, name }: { id: string; name: string; }) => (
                                            <MenuItem
                                                key={id}
                                                value={id}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                        select={loading === false}
                                        InputProps={{
                                            readOnly: loading
                                        }}
                                        fullWidth
                                        required
                                    />
                                )}
                            </Query>
                        </DialogContent>
                        <DialogActions>
                            <LoadingButton
                                {...submitButtonProps}
                                variant="contained"
                                type="submit"
                                disableElevation
                            >
                                Хадгалах
                            </LoadingButton>
                            <Button onClick={onClose}>
                                Хаах
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </Dialog>
    );
};
import { createReducer, PayloadAction } from '@reduxjs/toolkit';

export type AuthState = {
    accessToken: string | null;
    user: any;
};

const initialState: AuthState = {
    accessToken: null,
    user: null
};

const handlers = {
    SET_ACCESS_TOKEN(state: AuthState, { payload }: PayloadAction<Partial<AuthState>>) {
        return {
            ...state,
            ...payload
        };
    },
    SET_USER(state: AuthState, { payload }: PayloadAction<Partial<AuthState>>) {
        return {
            ...state,
            ...payload
        };
    }
};

export const auth = createReducer(
    initialState,
    handlers
);
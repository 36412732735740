import { ApolloError, gql, MutationFunction, MutationResult } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { Avatar, Box, Button, ButtonProps, Stack, styled, TableCell, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import { default as React } from 'react';
import { Clock, Download, Gift } from 'react-feather';
import { NumericFormat } from 'react-number-format';
import { connect } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { DataTable, DataTableColumn } from '../components/common/DataTable';
import { createUseState } from '../components/common/UseState';
import { CreateRewardsForm } from '../forms/CreateRewards';
import { parseErrors } from '../lib/helpers';
import { RootState } from '../redux/configureStore';
import { ValidationError } from '../types';

const UseState = createUseState<string>();
const UseStateBoolean = createUseState<boolean>();
const UseStateValidationErrors = createUseState<ValidationError[]>();

const USERS = gql`
    query Users(
        $search: String
        $skip: Int
        $take: Int
        $sortBy: String
        $sortOrder: String
    ) {
        users(
            search: $search
            skip: $skip
            take: $take
            sortBy: $sortBy
            sortOrder: $sortOrder
            where: {
                admin: false
            }
        ) {
            count
            edges {
                id
                phone
                avatar
                nickname
                drops
                createdAt
                trees {
                    count
                }
            }
        }
    }
`;

const CREATE_REWARDS = gql`
    mutation CreateRewards(
        $drops: Int
        $userIds: [String!]
    ) {
        createRewards(input: {
            drops: $drops
            userIds: $userIds
        })
    }
`;

const StyledButton = styled(Button)<ButtonProps & { component?: any; to?: string; download?: string | boolean; }>(() => ({
    lineHeight: '24px'
}));

type UsersProps = {
    accessToken: string | null;
};

const mapStateToProps = (state: RootState) => ({
    accessToken: state.auth.accessToken
});

export const Users = connect(mapStateToProps)(({ accessToken }: UsersProps): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar();

    return (
        <UseState defaultValue={uuidv4()}>
            {(key, setKey) => (
                <UseState defaultValue="">
                    {(search, setSearch) => (
                        <div>
                            <Typography variant="h4">
                                Хэрэглэгч
                            </Typography>
                            <Box marginY={2}>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <UseStateBoolean defaultValue={false}>
                                        {(open, setOpen) => (
                                            <UseStateValidationErrors defaultValue={[]}>
                                                {(errors, setErrors) => (
                                                    <React.Fragment>
                                                        <Mutation
                                                            mutation={CREATE_REWARDS}
                                                            onError={(err: ApolloError) => {
                                                                if (err.message === 'Bad Request Exception') {
                                                                    setErrors(parseErrors(err));
                                                                }
                                                            }}
                                                            onCompleted={() => {
                                                                setOpen(false);
                                                                setKey(uuidv4());
                                                                enqueueSnackbar('Амжилттай бэлэг илгээлээ', {
                                                                    autoHideDuration: 4000,
                                                                    variant: 'success'
                                                                });
                                                            }}
                                                        >
                                                            {(mutate: MutationFunction, { loading }: MutationResult) => (
                                                                <CreateRewardsForm
                                                                    open={open}
                                                                    onClose={() => setOpen(false)}
                                                                    initialValues={{
                                                                        drops: null,
                                                                        users: []
                                                                    }}
                                                                    errors={errors}
                                                                    onSubmit={({ users, ...values }) => {
                                                                        setErrors([]);
                                                                        mutate({
                                                                            variables: Object.assign(values, {
                                                                                userIds: users.map(({ id }) => id)
                                                                            })
                                                                        });
                                                                    }}
                                                                    submitButtonProps={{
                                                                        loading
                                                                    }}
                                                                />
                                                            )}
                                                        </Mutation>
                                                        <StyledButton
                                                            variant="contained"
                                                            size="large"
                                                            disableElevation
                                                            startIcon={
                                                                <Gift />
                                                            }
                                                            onClick={() => {
                                                                setErrors([]);
                                                                setOpen(true);
                                                            }}
                                                        >
                                                            Бэлэг
                                                        </StyledButton>
                                                    </React.Fragment>
                                                )}
                                            </UseStateValidationErrors>
                                        )}
                                    </UseStateBoolean>
                                    <StyledButton
                                        component={Link}
                                        to="/rewards"
                                        variant="outlined"
                                        size="large"
                                        disableElevation
                                        startIcon={
                                            <Clock />
                                        }
                                    >
                                        Түүх
                                    </StyledButton>
                                    <StyledButton
                                        variant="outlined"
                                        size="large"
                                        href={`${process.env.REACT_APP_API_URL}/user/xlsx?accessToken=${accessToken}`}
                                        download
                                        disableElevation
                                        startIcon={
                                            <Download />
                                        }
                                    >
                                        xlsx
                                    </StyledButton>
                                    <TextField
                                        label="Хайх"
                                        size="small"
                                        variant="outlined"
                                        onChange={debounce(e => {
                                            setSearch(e.target.value);
                                        }, 500)}
                                        sx={{
                                            minWidth: 300
                                        }}
                                        InputProps={{
                                            sx: {
                                                backgroundColor: '#fff'
                                            }
                                        }}
                                    />
                                </Stack>
                            </Box>
                            <DataTable
                                key={key}
                                query={USERS}
                                dataPath="users"
                                variables={{
                                    search
                                }}
                            >
                                <DataTableColumn
                                    title=""
                                    dataIndex="nickname"
                                    align="left"
                                    render={({ avatar, nickname }) => (
                                        <TableCell padding="none">
                                            <Box paddingX={2}>
                                                <Stack
                                                    alignItems="center"
                                                    direction="row"
                                                    spacing={1}
                                                >
                                                    <Avatar
                                                        src={`/static/images/avatars/${avatar}.png`}
                                                        sx={{
                                                            backgroundColor: '#f0f0f0'
                                                        }}
                                                    />
                                                    <Typography variant="body2">
                                                        {nickname}
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                        </TableCell>
                                    )}
                                />
                                <DataTableColumn
                                    title="Утас"
                                    dataIndex="phone"
                                    align="left"
                                    sorter
                                />
                                <DataTableColumn
                                    title="Модны тоо"
                                    dataIndex="trees._count"
                                    align="left"
                                    sorter
                                    render={({ trees }) => (
                                        <TableCell padding="normal">
                                            {trees.count}
                                        </TableCell>
                                    )}
                                />
                                <DataTableColumn
                                    title="Үлд."
                                    dataIndex="drops"
                                    align="left"
                                    sorter
                                    render={({ drops }) => (
                                        <TableCell padding="normal">
                                            <NumericFormat
                                                value={drops}
                                                displayType="text"
                                                suffix=" литр"
                                                thousandSeparator=","
                                            />
                                        </TableCell>
                                    )}
                                />
                                <DataTableColumn
                                    title="Огноо"
                                    dataIndex="createdAt"
                                    align="left"
                                    sorter
                                    render={({ createdAt }) => (
                                        <TableCell padding="normal">
                                            {format(createdAt, 'yyyy-MM-dd HH:mm:ss')}
                                        </TableCell>
                                    )}
                                />
                                <DataTableColumn
                                    title=""
                                    dataIndex="id"
                                    align="right"
                                    render={({ id }) => (
                                        <TableCell padding="none">
                                            <Button
                                                component={Link}
                                                to={generatePath('/users/:id', { id })}
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                            >
                                                Дэлгэрэнгүй
                                            </Button>
                                        </TableCell>
                                    )}
                                />
                            </DataTable>
                        </div>
                    )}
                </UseState>
            )}
        </UseState>
    );
});
import { Route, Routes } from 'react-router-dom';
import { Dashboard } from './layouts/Dashboard';
import { Admins } from './pages/Admins';
import { Authenticate } from './pages/Authenticate';
import { ChangePassword } from './pages/ChangePassword';
import { Home } from './pages/Home';
import { NotFound } from './pages/NotFound';
import { Options } from './pages/Options';
import { Partners } from './pages/Partners';
import { Places } from './pages/Places';
import { Purchases } from './pages/Purchases';
import { Rewards } from './pages/Rewards';
import { Trees } from './pages/Trees';
import { Types } from './pages/Types';
import { User } from './pages/User';
import { Users } from './pages/Users';

export const Router = (): JSX.Element => {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <Dashboard />
                }
            >
                <Route
                    index
                    element={
                        <Home />
                    }
                />
                <Route
                    path="/admins"
                    element={
                        <Admins />
                    }
                />
                <Route
                    path="/change-password"
                    element={
                        <ChangePassword />
                    }
                />
                <Route
                    path="/options"
                    element={
                        <Options />
                    }
                />
                <Route
                    path="/partners"
                    element={
                        <Partners />
                    }
                />
                <Route
                    path="/places"
                    element={
                        <Places />
                    }
                />
                <Route
                    path="/purchases"
                    element={
                        <Purchases />
                    }
                />
                <Route
                    path="/rewards"
                    element={
                        <Rewards />
                    }
                />
                <Route
                    path="/trees"
                    element={
                        <Trees />
                    }
                />
                <Route
                    path="/types"
                    element={
                        <Types />
                    }
                />
                <Route
                    path="/users"
                    element={
                        <Users />
                    }
                />
                <Route
                    path="/users/:id"
                    element={
                        <User />
                    }
                />
            </Route>
            <Route
                path="/signin"
                element={
                    <Authenticate />
                }
            />
            <Route
                path="*"
                element={
                    <NotFound />
                }
            />
        </Routes>
    );
};
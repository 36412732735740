import { Box, Card, Chip, Grid, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { TreeAnimation } from '../components/TreeAnimation';

type TreeProps = {
    id: string;
    drops: number;
    requiredDrops: number;
    progress: number;
    createdAt: number;
    typeName: string;
    typeCode: 'aspen' | 'elm' | 'pine' | 'willow' | 'sea-buckthorn';
    placeName: string;
    certificateNumber?: string;
};

export const Tree = (props: TreeProps): JSX.Element => {
    const {
        id,
        drops,
        requiredDrops,
        progress,
        createdAt,
        typeName,
        typeCode,
        placeName,
        certificateNumber
    } = props;

    return (
        <Card
            elevation={0}
            sx={{
                backgroundColor: '#f0f0f0'
            }}
        >
            <Box padding={2}>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                    spacing={1}
                >
                    <Typography
                        variant="h6"
                        textAlign="center"
                    >
                        {typeName}
                    </Typography>
                    <Chip
                        label={`${progress}%`}
                        size="small"
                        color="primary"
                        sx={{
                            borderRadius: '4px'
                        }}
                    />
                </Stack>
                <Grid
                    alignItems="center"
                    container
                    sx={{
                        marginY: 4
                    }}
                >
                    <Grid item xs>
                        <TreeAnimation
                            progress={progress}
                            code={typeCode}
                        />
                    </Grid>
                    <Grid item xs>
                        <Stack spacing={1}>
                            <Card variant="outlined">
                                <Box
                                    sx={{
                                        paddingY: '4px',
                                        paddingX: 2
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        Тарьж эхэлсэн
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {format(createdAt, 'yyyy-MM-dd')}
                                    </Typography>
                                </Box>
                            </Card>
                            <Card variant="outlined">
                                <Box
                                    sx={{
                                        paddingY: '4px',
                                        paddingX: 2
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        Усалсан
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {drops} литр
                                    </Typography>
                                </Box>
                            </Card>
                            <Card variant="outlined">
                                <Box
                                    sx={{
                                        paddingY: '4px',
                                        paddingX: 2
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        Услах дутуу
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {requiredDrops - drops} литр
                                    </Typography>
                                </Box>
                            </Card>
                        </Stack>
                    </Grid>
                </Grid>
                <Stack
                    alignItems="center"
                    spacing={1}
                >
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        textAlign="center"
                    >
                        Сертификатны дугаар: <strong>{certificateNumber ?? 'Дуусаагүй'}</strong>
                    </Typography>
                    <Card variant="outlined">
                        <Box
                            paddingY="4px"
                            paddingX="8px"
                        >
                            <Typography
                                variant="subtitle2"
                                color="primary"
                            >
                                {placeName}
                            </Typography>
                        </Box>
                    </Card>
                </Stack>
            </Box>
        </Card>
    );
};
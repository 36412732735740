import { Box, styled } from '@mui/material';
import { connect } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { AppBar } from '../components/app/AppBar';
import { AppDrawer, drawerWidth } from '../components/app/AppDrawer';
import { createUseState } from '../components/common/UseState';
import { ThemeContext } from '../contexts/theme-context';
import { RootState } from '../redux/configureStore';

const UseState = createUseState<boolean>();

export const AppBarSpacer = (): JSX.Element => {
    return (
        <Box
            sx={{
                paddingTop: {
                    xs: 6,
                    lg: 0
                }
            }}
        />
    );
};

const Root = styled('div')(({ theme }) => ({
    display: 'flex'
}));

const Main = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        flexGrow: 1,
        padding: 24
    },
    [theme.breakpoints.up('lg')]: {
        padding: 40,
        width: `calc(100% - ${drawerWidth}px)`
    }
}));

type DashboardProps = {
    user: any;
};

const mapStateToProps = (state: RootState) => ({
    user: state.auth.user
});

export const Dashboard = connect(mapStateToProps)((props: DashboardProps): JSX.Element => {
    if (props.user === null) {
        return (
            <Navigate
                to="/signin"
                replace
            />
        );
    }

    return (
        <UseState defaultValue={false}>
            {(open, setOpen) => (
                <ThemeContext.Provider value={{
                    open,
                    setOpen
                }}>
                    <AppBarSpacer />
                    <AppBar />
                    <Root>
                        <AppDrawer />
                        <Main>
                            <Outlet />
                        </Main>
                    </Root>
                </ThemeContext.Provider>
            )}
        </UseState>
    );
});
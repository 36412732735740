import { ApolloError, gql, MutationFunction, MutationResult } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { Avatar, Box, Button, ButtonProps, Chip, IconButton, Link as MuiLink, Stack, styled, TableCell, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import { Fragment } from 'react';
import { CheckCircle, Download, Edit, FileText } from 'react-feather';
import { connect } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Confirm } from '../components/common/Confirm';
import { DataTable, DataTableColumn } from '../components/common/DataTable';
import { createUseState } from '../components/common/UseState';
import { TreeForm } from '../forms/Tree';
import { parseErrors } from '../lib/helpers';
import { RootState } from '../redux/configureStore';
import { ValidationError } from '../types';

const UseStateString = createUseState<string>();
const UseStateBoolean = createUseState<boolean>();
const UseStateValidationErrors = createUseState<ValidationError[]>();

const TREES = gql`
    query Trees(
        $search: String
        $skip: Int
        $take: Int
        $sortBy: String
        $sortOrder: String
    ) {
        trees(
            search: $search
            skip: $skip
            take: $take
            sortBy: $sortBy
            sortOrder: $sortOrder
        ) {
            count
            edges {
                id
                drops  
                requiredDrops
                plantedAt
                progress
                createdAt
                user {
                    id
                    phone
                    avatar
                    nickname
                }
                type {
                    name
                    code
                }
                place {
                    name
                }
                certificate {
                    number
                }
            }
        }
    }
`;

const UPDATE_TREE = gql`
    mutation UpdateTree(
        $id: String!
        $plantedAt: String
    ) {
        updateTree(input: {
            id: $id
            plantedAt: $plantedAt
        }) {
            id
        }
    }
`;

const GENERATE_CERTIFICATE = gql`
    mutation GenerateCertificate($id: String!) {
        generateCertificate(id: $id)
    }
`;

const StyledButton = styled(Button)<ButtonProps & { download?: string | boolean; }>(() => ({
    lineHeight: '24px'
}));

type TreesProps = {
    accessToken: string | null;
};

const mapStateToProps = (state: RootState) => ({
    accessToken: state.auth.accessToken
});

export const Trees = connect(mapStateToProps)(({ accessToken }: TreesProps): JSX.Element => {
    return (
        <UseStateString defaultValue={uuidv4()}>
            {(key, setKey) => (
                <UseStateString defaultValue="">
                    {(search, setSearch) => (
                        <div>
                            <Typography variant="h4">
                                Мод
                            </Typography>
                            <Box marginY={2}>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <StyledButton
                                        variant="outlined"
                                        size="large"
                                        href={`${process.env.REACT_APP_API_URL}/tree/xlsx?accessToken=${accessToken}`}
                                        download
                                        disableElevation
                                        startIcon={
                                            <Download />
                                        }
                                    >
                                        xlsx
                                    </StyledButton>
                                    <TextField
                                        label="Хайх"
                                        size="small"
                                        variant="outlined"
                                        onChange={debounce(e => {
                                            setSearch(e.target.value);
                                        }, 500)}
                                        sx={{
                                            minWidth: 300
                                        }}
                                        InputProps={{
                                            sx: {
                                                backgroundColor: '#fff'
                                            }
                                        }}
                                    />
                                </Stack>
                            </Box>
                            <DataTable
                                key={key}
                                query={TREES}
                                dataPath="trees"
                                variables={{
                                    search
                                }}
                            >
                                <DataTableColumn
                                    title="Хэрэглэгч"
                                    dataIndex="nickname"
                                    align="left"
                                    render={({ user }) => (
                                        <TableCell padding="none">
                                            <Box paddingX={2}>
                                                <Stack
                                                    alignItems="center"
                                                    direction="row"
                                                    spacing={1}
                                                >
                                                    <Avatar
                                                        src={`/static/images/avatars/${user.avatar}.png`}
                                                        sx={{
                                                            backgroundColor: '#f0f0f0'
                                                        }}
                                                    />
                                                    <MuiLink
                                                        component={Link}
                                                        to={generatePath('/users/:id', { id: user.id })}
                                                        variant="body2"
                                                        underline="none"
                                                    >
                                                        {user.nickname}
                                                    </MuiLink>
                                                </Stack>
                                            </Box>
                                        </TableCell>
                                    )}
                                />
                                <DataTableColumn
                                    title=""
                                    dataIndex="user.phone"
                                    align="left"
                                />
                                <DataTableColumn
                                    title="Төрөл"
                                    dataIndex="type.name"
                                    align="left"
                                />
                                <DataTableColumn
                                    title="Явц"
                                    dataIndex="progress"
                                    align="left"
                                    render={({ progress }) => (
                                        <TableCell padding="none">
                                            <Box paddingX={2}>
                                                <Chip
                                                    label={`${progress}%`}
                                                    size="small"
                                                    color="primary"
                                                    sx={{
                                                        borderRadius: '4px'
                                                    }}
                                                />
                                            </Box>
                                        </TableCell>
                                    )}
                                />
                                <DataTableColumn
                                    title="Байршил"
                                    dataIndex="place.name"
                                    align="left"
                                />
                                <DataTableColumn
                                    title="Сертификат №"
                                    dataIndex="certificate.number"
                                    align="left"
                                />
                                <DataTableColumn
                                    title="Тарьсан огноо"
                                    dataIndex="plantedAt"
                                    align="left"
                                    render={({ plantedAt }) => (
                                        <TableCell padding="none">
                                            <Box paddingX={2}>
                                                {plantedAt && (
                                                    <Chip
                                                        label={format(plantedAt, 'yyyy-MM-dd')}
                                                        size="small"
                                                        color="success"
                                                        icon={
                                                            <CheckCircle size={18} />
                                                        }
                                                        sx={{
                                                            borderRadius: '4px'
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        </TableCell>
                                    )}
                                />
                                <DataTableColumn
                                    title="Огноо"
                                    dataIndex="createdAt"
                                    align="left"
                                    sorter
                                    render={({ createdAt }) => (
                                        <TableCell padding="normal">
                                            {format(createdAt, 'yyyy-MM-dd HH:mm:ss')}
                                        </TableCell>
                                    )}
                                />
                                <DataTableColumn
                                    title=""
                                    dataIndex="id"
                                    align="right"
                                    render={({ id, certificate, plantedAt }) => (
                                        <TableCell padding="none">
                                            <Box paddingX={2}>
                                                <UseStateBoolean defaultValue={false}>
                                                    {(open, setOpen) => (
                                                        <UseStateValidationErrors defaultValue={[]}>
                                                            {(errors, setErrors) => (
                                                                <Fragment>
                                                                    <Mutation
                                                                        mutation={UPDATE_TREE}
                                                                        onError={(err: ApolloError) => {
                                                                            if (err.message === 'Bad Request Exception') {
                                                                                setErrors(parseErrors(err));
                                                                            }
                                                                        }}
                                                                        onCompleted={() => {
                                                                            setOpen(false);
                                                                            setKey(uuidv4());
                                                                        }}
                                                                    >
                                                                        {(mutate: MutationFunction, { loading }: MutationResult) => (
                                                                            <TreeForm
                                                                                open={open}
                                                                                onClose={() => setOpen(false)}
                                                                                initialValues={{
                                                                                    plantedAt
                                                                                }}
                                                                                errors={errors}
                                                                                onSubmit={values => {
                                                                                    setErrors([]);
                                                                                    mutate({
                                                                                        variables: Object.assign(values, {
                                                                                            id
                                                                                        })
                                                                                    });
                                                                                }}
                                                                                submitButtonProps={{
                                                                                    loading
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Mutation>
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            setErrors([]);
                                                                            setOpen(true);
                                                                        }}
                                                                    >
                                                                        <Edit />
                                                                    </IconButton>
                                                                </Fragment>
                                                            )}
                                                        </UseStateValidationErrors>
                                                    )}
                                                </UseStateBoolean>
                                                <UseStateBoolean defaultValue={false}>
                                                    {(open, setOpen) => (
                                                        <Mutation
                                                            mutation={GENERATE_CERTIFICATE}
                                                            variables={{
                                                                id
                                                            }}
                                                            onError={(err: ApolloError) => {
                                                                //
                                                            }}
                                                            onCompleted={() => {
                                                                setOpen(false);
                                                            }}
                                                        >
                                                            {(mutate: MutationFunction, { loading }: MutationResult) => (
                                                                <Fragment>
                                                                    <IconButton
                                                                        disabled={certificate === null}
                                                                        onClick={() => setOpen(true)}
                                                                    >
                                                                        <FileText />
                                                                    </IconButton>
                                                                    <Confirm
                                                                        open={open}
                                                                        onClose={() => setOpen(false)}
                                                                        title="Сертификат дахин үүсгэх"
                                                                        description={`Та "${certificate?.number}" модны сертификат дахин үүсгэхийг зөвшөөрч байна уу?`}
                                                                        okText="Тийм"
                                                                        cancelText="Үгүй"
                                                                        onOk={mutate}
                                                                        onCancel={() => setOpen(false)}
                                                                        okButtonProps={{
                                                                            loading
                                                                        }}
                                                                    />
                                                                </Fragment>
                                                            )}
                                                        </Mutation>
                                                    )}
                                                </UseStateBoolean>
                                            </Box>
                                        </TableCell>
                                    )}
                                />
                            </DataTable>
                        </div>
                    )}
                </UseStateString>
            )}
        </UseStateString>
    );
});
import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { Droplet, Feather, Home, Lock, LogOut, MapPin, Shield, ShoppingBag, Sliders, User } from 'react-feather';
import { createUseStyles } from 'react-jss';
import { connect } from 'react-redux';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { ThemeContext } from '../../contexts/theme-context';
import { setAccessToken, setUser } from '../../redux/auth/actions';

export const drawerWidth = 280;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0
}));

const useStyles = createUseStyles({
    drawerPaper: {
        width: drawerWidth,
        boxSizing: 'border-box',
        backgroundColor: '#fff !important'
    },
    listItemIcon: {
        minWidth: '36px !important'
    }
});

interface ListItemProps extends NavLinkProps {
    component?: React.ElementType;
}

const StyledListItemButton = styled(ListItemButton)<ListItemProps>(() => ({
    borderRadius: 8
}));

type AppDrawerProps = {
    setAccessToken: (accessToken: string | null) => void;
    setUser: (user: any) => void;
};

const AppDrawerComponent = (props: AppDrawerProps): JSX.Element => {
    const isMobile = useMediaQuery(useTheme().breakpoints.down('lg'));

    const {
        drawerPaper,
        listItemIcon
    } = useStyles();

    const content = (
        <div>
            <Box padding={3}>
                <Stack
                    alignItems="center"
                    direction="row"
                >
                    <Box
                        component="img"
                        src="/static/images/logo.png"
                        alt="ocademy logo"
                        sx={{
                            height: 24,
                            margin: '0px auto'
                        }}
                    />
                </Stack>
            </Box>
            <Box marginTop={6}>
                <Box padding={1}>
                    <Box marginBottom={2}>
                        <Box paddingX={2}>
                            <Typography variant="subtitle2">
                                Үндсэн
                            </Typography>
                        </Box>
                    </Box>
                    <List
                        disablePadding
                        dense
                    >
                        <ListItem disablePadding>
                            <StyledListItemButton
                                component={NavLink}
                                to="/"
                            >
                                <ListItemIcon className={listItemIcon}>
                                    <Home
                                        size={20}
                                        color="#7e84a3"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Нүүр"
                                    primaryTypographyProps={{
                                        variant: 'subtitle2',
                                        color: '#7e84a3'
                                    }}
                                />
                            </StyledListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <StyledListItemButton
                                component={NavLink}
                                to="/trees"
                            >
                                <ListItemIcon className={listItemIcon}>
                                    <Feather
                                        size={20}
                                        color="#7e84a3"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Мод"
                                    primaryTypographyProps={{
                                        variant: 'subtitle2',
                                        color: '#7e84a3'
                                    }}
                                />
                            </StyledListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <StyledListItemButton
                                component={NavLink}
                                to="/users"
                            >
                                <ListItemIcon className={listItemIcon}>
                                    <User
                                        size={20}
                                        color="#7e84a3"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Хэрэглэгч"
                                    primaryTypographyProps={{
                                        variant: 'subtitle2',
                                        color: '#7e84a3'
                                    }}
                                />
                            </StyledListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <StyledListItemButton
                                component={NavLink}
                                to="/purchases"
                            >
                                <ListItemIcon className={listItemIcon}>
                                    <ShoppingBag
                                        size={20}
                                        color="#7e84a3"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Худалдан авалт"
                                    primaryTypographyProps={{
                                        variant: 'subtitle2',
                                        color: '#7e84a3'
                                    }}
                                />
                            </StyledListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <StyledListItemButton
                                component={NavLink}
                                to="/admins"
                            >
                                <ListItemIcon className={listItemIcon}>
                                    <Shield
                                        size={20}
                                        color="#7e84a3"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Админ"
                                    primaryTypographyProps={{
                                        variant: 'subtitle2',
                                        color: '#7e84a3'
                                    }}
                                />
                            </StyledListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <StyledListItemButton
                                component={NavLink}
                                to="/options"
                            >
                                <ListItemIcon className={listItemIcon}>
                                    <Sliders
                                        size={20}
                                        color="#7e84a3"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Тохиргоо"
                                    primaryTypographyProps={{
                                        variant: 'subtitle2',
                                        color: '#7e84a3'
                                    }}
                                />
                            </StyledListItemButton>
                        </ListItem>
                    </List>
                    <Box marginY={6}>
                        <Box paddingX={2}>
                            <Divider />
                        </Box>
                    </Box>
                    <Box marginBottom={2}>
                        <Box paddingX={2}>
                            <Typography variant="subtitle2">
                                Агуулга
                            </Typography>
                        </Box>
                    </Box>
                    <List
                        disablePadding
                        dense
                    >
                        <ListItem disablePadding>
                            <StyledListItemButton
                                component={NavLink}
                                to="/partners"
                            >
                                <ListItemIcon className={listItemIcon}>
                                    <Droplet
                                        size={20}
                                        color="#7e84a3"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Мод тарих байгууллага"
                                    primaryTypographyProps={{
                                        variant: 'subtitle2',
                                        color: '#7e84a3'
                                    }}
                                />
                            </StyledListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <StyledListItemButton
                                component={NavLink}
                                to="/places"
                            >
                                <ListItemIcon className={listItemIcon}>
                                    <MapPin
                                        size={20}
                                        color="#7e84a3"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Байршил"
                                    primaryTypographyProps={{
                                        variant: 'subtitle2',
                                        color: '#7e84a3'
                                    }}
                                />
                            </StyledListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <StyledListItemButton
                                component={NavLink}
                                to="/types"
                            >
                                <ListItemIcon className={listItemIcon}>
                                    <Feather
                                        size={20}
                                        color="#7e84a3"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Модны төрөл"
                                    primaryTypographyProps={{
                                        variant: 'subtitle2',
                                        color: '#7e84a3'
                                    }}
                                />
                            </StyledListItemButton>
                        </ListItem>
                    </List>
                    <Box marginY={6}>
                        <Box paddingX={2}>
                            <Divider />
                        </Box>
                    </Box>
                    <Box marginBottom={2}>
                        <Box paddingX={2}>
                            <Typography variant="subtitle2">
                                Хэрэглэгч
                            </Typography>
                        </Box>
                    </Box>
                    <List
                        disablePadding
                        dense
                    >
                        <ListItem disablePadding>
                            <StyledListItemButton
                                component={NavLink}
                                to="/change-password"
                            >
                                <ListItemIcon className={listItemIcon}>
                                    <Lock
                                        size={20}
                                        color="#7e84a3"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Нууц үг солих"
                                    primaryTypographyProps={{
                                        variant: 'subtitle2',
                                        color: '#7e84a3'
                                    }}
                                />
                            </StyledListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={() => {
                                    props.setAccessToken(null);
                                    props.setUser(null);
                                }}
                                sx={{
                                    borderRadius: 1
                                }}
                            >
                                <ListItemIcon className={listItemIcon}>
                                    <LogOut
                                        size={20}
                                        color="#7e84a3"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Гарах"
                                    primaryTypographyProps={{
                                        variant: 'subtitle2',
                                        color: '#7e84a3'
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </div>
    );

    return (
        <ThemeContext.Consumer>
            {({ open, setOpen }) => (
                <React.Fragment>
                    {isMobile
                        ?
                        <Drawer
                            variant="temporary"
                            anchor="left"
                            open={open}
                            onClose={() => setOpen(false)}
                            classes={{
                                paper: drawerPaper
                            }}
                            ModalProps={{
                                keepMounted: true
                            }}
                        >
                            {content}
                        </Drawer>
                        :
                        <StyledDrawer
                            variant="permanent"
                            anchor="left"
                            open={true}
                            classes={{
                                paper: drawerPaper
                            }}
                        >
                            {content}
                        </StyledDrawer>
                    }
                </React.Fragment>
            )}
        </ThemeContext.Consumer>
    );
};

const mapDispatchToProps = {
    setAccessToken,
    setUser
};

export const AppDrawer = connect(null, mapDispatchToProps)(AppDrawerComponent);
import { gql } from '@apollo/client';
import { Avatar, Box, Button, ButtonProps, Chip, Stack, styled, TableCell, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import { Download } from 'react-feather';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { DataTable, DataTableColumn } from '../components/common/DataTable';
import { createUseState } from '../components/common/UseState';
import { RootState } from '../redux/configureStore';

const UseStateString = createUseState<string>();

const PURCHASES = gql`
    query Purchases(
        $search: String
        $skip: Int
        $take: Int
        $sortBy: String
        $sortOrder: String
    ) {
        purchases(
            search: $search
            skip: $skip
            take: $take
            sortBy: $sortBy
            sortOrder: $sortOrder
        ) {
            count
            edges {
                id
                method
                drops
                transactionId
                createdAt
                user {
                    id
                    phone
                    avatar
                    nickname
                }
            }
        }
    }
`;

const StyledButton = styled(Button)<ButtonProps & { download?: string | boolean; }>(() => ({
    lineHeight: '24px'
}));

type PurchasesProps = {
    accessToken: string | null;
};

const mapStateToProps = (state: RootState) => ({
    accessToken: state.auth.accessToken
});

export const Purchases = connect(mapStateToProps)(({ accessToken }: PurchasesProps): JSX.Element => {
    return (
        <UseStateString defaultValue={uuidv4()}>
            {(key, setKey) => (
                <UseStateString defaultValue="">
                    {(search, setSearch) => (
                        <div>
                            <Typography variant="h4">
                                Худалдан авалт
                            </Typography>
                            <Box marginY={2}>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <StyledButton
                                        variant="outlined"
                                        size="large"
                                        href={`${process.env.REACT_APP_API_URL}/transaction/xlsx?accessToken=${accessToken}`}
                                        download
                                        disableElevation
                                        startIcon={
                                            <Download />
                                        }
                                    >
                                        xlsx
                                    </StyledButton>
                                    <TextField
                                        label="Хайх"
                                        size="small"
                                        variant="outlined"
                                        onChange={debounce(e => {
                                            setSearch(e.target.value);
                                        }, 500)}
                                        sx={{
                                            minWidth: 300
                                        }}
                                        InputProps={{
                                            sx: {
                                                backgroundColor: '#fff'
                                            }
                                        }}
                                    />
                                </Stack>
                            </Box>
                            <DataTable
                                key={key}
                                query={PURCHASES}
                                dataPath="purchases"
                                variables={{
                                    search
                                }}
                            >
                                <DataTableColumn
                                    title=""
                                    dataIndex="user"
                                    align="left"
                                    render={({ user }) => (
                                        <TableCell padding="none">
                                            <Box paddingX={2}>
                                                <Stack
                                                    alignItems="center"
                                                    direction="row"
                                                    spacing={1}
                                                >
                                                    <Avatar
                                                        src={`/static/images/avatars/${user.avatar}.png`}
                                                        sx={{
                                                            backgroundColor: '#f0f0f0'
                                                        }}
                                                    />
                                                    <Typography variant="body2">
                                                        {user.nickname}
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                        </TableCell>
                                    )}
                                />
                                <DataTableColumn
                                    title="Утас"
                                    dataIndex="user.phone"
                                    align="left"
                                    sorter
                                />
                                <DataTableColumn
                                    title="Усны хэмжээ"
                                    dataIndex="drops"
                                    align="left"
                                    sorter
                                    render={({ drops }) => (
                                        <TableCell padding="normal">
                                            {drops} литр
                                        </TableCell>
                                    )}
                                />
                                <DataTableColumn
                                    title=""
                                    dataIndex="method"
                                    align="left"
                                    sorter
                                    render={({ method }) => (
                                        <TableCell padding="none">
                                            <Box paddingX={2}>
                                                {method === 'payon'
                                                    ?
                                                    <Chip
                                                        label="Худалдаж авсан"
                                                        color="primary"
                                                        size="small"
                                                        sx={{
                                                            borderRadius: '4px'
                                                        }}
                                                    />
                                                    :
                                                    <Chip
                                                        label="Оноо шилжүүлсэн"
                                                        color="success"
                                                        size="small"
                                                        sx={{
                                                            borderRadius: '4px'
                                                        }}
                                                    />
                                                }
                                            </Box>
                                        </TableCell>
                                    )}
                                />
                                <DataTableColumn
                                    title="Payon transaction ID"
                                    dataIndex="transactionId"
                                    align="left"
                                />
                                <DataTableColumn
                                    title="Огноо"
                                    dataIndex="createdAt"
                                    align="left"
                                    sorter
                                    render={({ createdAt }) => (
                                        <TableCell padding="normal">
                                            {format(createdAt, 'yyyy-MM-dd HH:mm:ss')}
                                        </TableCell>
                                    )}
                                />
                            </DataTable>
                        </div>
                    )}
                </UseStateString>
            )}
        </UseStateString>
    );
});
import { Box, CircularProgress, IconButton, Link, Typography } from '@mui/material';
import { default as clsx } from 'clsx';
import { default as React } from 'react';
import { Trash2, Upload } from 'react-feather';
import { createUseStyles } from 'react-jss';
import { useUpload } from '../../hooks/useUpload';

const useStyles = createUseStyles({
    input: {
        display: 'none !important'
    },
    dropZone: {
        marginTop: 16,
        marginBottom: 8,
        padding: 16,
        cursor: 'pointer',
        textAlign: 'center',
        border: '2px dashed rgba(0, 0, 0, 0.12)',
        borderRadius: 8,
        backgroundColor: '#f0f0f0'
    },
    dropZoneFile: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'default'
    },
    hasError: {
        backgroundColor: 'rgba(211, 47, 47, 0.1)',
        borderColor: '#d32f2f',
        color: '#d32f2f'
    }
});

type DropZoneProps = {
    error: boolean;
    description?: string;
    url: string;
    value?: string;
    onChange: (value: any) => void;
};

export const DropZone = (props: DropZoneProps): JSX.Element => {
    const inputRef = React.useRef<HTMLInputElement | null>(null);

    const {
        input,
        dropZone,
        dropZoneFile,
        hasError
    } = useStyles();
    const {
        description = 'Энд дарж хуулах файлаа сонгоно уу',
        error,
        url,
        value,
        onChange,
    } = props;

    const [
        upload,
        uploadResult
    ] = useUpload({
        url,
        onError: err => {
            //
        }
    });

    return (
        <div>
            {value
                ?
                <div className={clsx(dropZone, dropZoneFile, error && hasError)}>
                    <Link
                        href={`${process.env.REACT_APP_API_URL}${value}`}
                        underline="none"
                        rel="noopener"
                        target="_blank"
                    >
                        {value}
                    </Link>
                    <IconButton
                        color="error"
                        onClick={() => {
                            onChange(null);
                        }}
                    >
                        <Trash2 />
                    </IconButton>
                </div>
                :
                <label>
                    <input
                        type="file"
                        ref={inputRef}
                        disabled={uploadResult.uploading}
                        onClick={() => inputRef.current = null}
                        onChange={async (e: any) => onChange(await upload(e.target.files[0]))}
                        className={input}
                    />
                    <div className={clsx(dropZone, error && hasError)}>
                        <Box marginBottom={2}>
                            {uploadResult.uploading
                                ?
                                <CircularProgress
                                    size={48}
                                    value={uploadResult.progress}
                                    variant="determinate"
                                />
                                :
                                <Upload
                                    size={48}
                                    color="#7e84a3"
                                />
                            }
                        </Box>
                        <Typography variant="body1">
                            {description}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                                marginTop: '4px'
                            }}
                        >
                            Support for a single upload
                        </Typography>
                    </div>
                </label>
            }
        </div>
    );
};
import { ApolloError, gql, MutationFunction, MutationResult } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { LoadingButton } from '@mui/lab';
import { Grid, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { createUseState } from '../components/common/UseState';
import { getValidationErrorMessage, parseErrors } from '../lib/helpers';
import { ValidationError } from '../types';

const UseState = createUseState<ValidationError[]>();

const CHANGE_PASSWORD = gql`
    mutation ChangePassword(
        $password: String
        $passwordConfirmation: String
    ) {
        changePassword(input: {
            password: $password
            passwordConfirmation: $passwordConfirmation
        }) {
            id
        }
    }
`;

export const ChangePassword = (): JSX.Element => {
    const { enqueueSnackbar } = useSnackbar();

    return (
        <div>
            <Grid
                justifyContent="center"
                container
            >
                <Grid
                    xs={12}
                    sm={8}
                    lg={5}
                    xl={4}
                    item
                >
                    <Typography
                        variant="h4"
                        sx={{
                            marginBottom: 2
                        }}
                    >
                        Нууц үг солих
                    </Typography>
                    <UseState defaultValue={[]}>
                        {(errors, setErrors) => (
                            <Mutation
                                mutation={CHANGE_PASSWORD}
                                onError={(err: ApolloError) => {
                                    if (err.message === 'Bad Request Exception') {
                                        setErrors(parseErrors(err));
                                    }
                                }}
                                onCompleted={() => {
                                    enqueueSnackbar('Таны нууц үг шинэчлэгдлээ', {
                                        autoHideDuration: 4000,
                                        variant: 'success'
                                    });
                                }}
                            >
                                {(mutate: MutationFunction, { loading }: MutationResult) => (
                                    <Formik
                                        initialValues={{
                                            password: '',
                                            passwordConfirmation: ''
                                        }}
                                        onSubmit={(values, { resetForm }) => {
                                            setErrors([]);
                                            mutate({
                                                variables: values
                                            });
                                        }}
                                    >
                                        {({ values, handleChange, handleSubmit }) => (
                                            <form
                                                onSubmit={handleSubmit}
                                                autoComplete="off"
                                                noValidate
                                            >
                                                <TextField
                                                    label="Нууц үг"
                                                    name="password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    error={errors.some(({ property }) => property === 'password')}
                                                    helperText={getValidationErrorMessage(errors, 'password')}
                                                    margin="normal"
                                                    type="password"
                                                    variant="standard"
                                                    fullWidth
                                                />
                                                <TextField
                                                    label="Нууц үг давтах"
                                                    name="passwordConfirmation"
                                                    value={values.passwordConfirmation}
                                                    onChange={handleChange}
                                                    error={errors.some(({ property }) => property === 'passwordConfirmation')}
                                                    helperText={getValidationErrorMessage(errors, 'passwordConfirmation')}
                                                    margin="normal"
                                                    type="password"
                                                    variant="standard"
                                                    fullWidth
                                                />
                                                <LoadingButton
                                                    variant="contained"
                                                    size="large"
                                                    type="submit"
                                                    loading={loading}
                                                    disableElevation
                                                    sx={{
                                                        marginTop: 2
                                                    }}
                                                >
                                                    Хадгалах
                                                </LoadingButton>
                                            </form>
                                        )}
                                    </Formik>
                                )}
                            </Mutation>
                        )}
                    </UseState>
                </Grid>
            </Grid>
        </div>
    );
};
import { ApolloError, gql, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { default as CountUp } from 'react-countup';

const USERS = gql`
    query Users {
        users(where: {
            admin: false
        }) {
            count
        }
    }
`;

const TREES = gql`
    query Trees {
        trees {
            count
        }
    }
`;

const CERTIFICATES = gql`
    query Certificates {
        certificates {
            count
        }
    }
`;

const TOTAL_DROPS = gql`
    query TotalDrops {
        totalDrops
    }
`;

export const Home = (): JSX.Element => {
    return (
        <div>
            <Typography
                variant="h4"
                sx={{
                    marginBottom: 2
                }}
            >
                Нүүр
            </Typography>
            <Grid
                spacing={2}
                container
            >
                <Grid
                    xs={12}
                    sm={6}
                    md={4}
                    xl={3}
                    item
                >
                    <Paper variant="outlined">
                        <Box padding={2}>
                            <Typography
                                color="text.secondary"
                                variant="subtitle2"
                                textTransform="uppercase"
                            >
                                Нийт хэрэглэгч
                            </Typography>
                            <Query
                                fetchPolicy="no-cache"
                                query={USERS}
                                onError={(err: ApolloError) => {
                                    //
                                }}
                            >
                                {({ data }: QueryResult) => (
                                    <Typography variant="h6">
                                        <CountUp
                                            duration={1}
                                            end={data?.users.count ?? 0}
                                        />
                                    </Typography>
                                )}
                            </Query>
                        </Box>
                    </Paper>
                </Grid>
                <Grid
                    xs={12}
                    sm={6}
                    md={4}
                    xl={3}
                    item
                >
                    <Paper variant="outlined">
                        <Box padding={2}>
                            <Typography
                                color="text.secondary"
                                variant="subtitle2"
                                textTransform="uppercase"
                            >
                                Нийт мод
                            </Typography>
                            <Query
                                fetchPolicy="no-cache"
                                query={TREES}
                                onError={(err: ApolloError) => {
                                    //
                                }}
                            >
                                {({ data }: QueryResult) => (
                                    <Typography variant="h6">
                                        <CountUp
                                            duration={1}
                                            end={data?.trees.count ?? 0}
                                        />
                                    </Typography>
                                )}
                            </Query>
                        </Box>
                    </Paper>
                </Grid>
                <Grid
                    xs={12}
                    sm={6}
                    md={4}
                    xl={3}
                    item
                >
                    <Paper variant="outlined">
                        <Box padding={2}>
                            <Typography
                                color="text.secondary"
                                variant="subtitle2"
                                textTransform="uppercase"
                            >
                                Нийт сертификат
                            </Typography>
                            <Query
                                fetchPolicy="no-cache"
                                query={CERTIFICATES}
                                onError={(err: ApolloError) => {
                                    //
                                }}
                            >
                                {({ data }: QueryResult) => (
                                    <Typography variant="h6">
                                        <CountUp
                                            duration={1}
                                            end={data?.certificates.count ?? 0}
                                        />
                                    </Typography>
                                )}
                            </Query>
                        </Box>
                    </Paper>
                </Grid>
                <Grid
                    xs={12}
                    sm={6}
                    md={4}
                    xl={3}
                    item
                >
                    <Paper variant="outlined">
                        <Box padding={2}>
                            <Typography
                                color="text.secondary"
                                variant="subtitle2"
                                textTransform="uppercase"
                            >
                                Нийт зарцуулсан ус
                            </Typography>
                            <Query
                                fetchPolicy="no-cache"
                                query={TOTAL_DROPS}
                                onError={(err: ApolloError) => {
                                    //
                                }}
                            >
                                {({ data }: QueryResult) => (
                                    <Typography variant="h6">
                                        <CountUp
                                            duration={1}
                                            end={data?.totalDrops ?? 0}
                                        />
                                    </Typography>
                                )}
                            </Query>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};
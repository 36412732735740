import { ApolloError, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Box, CircularProgress, Pagination, PaginationItem, Paper, Stack, styled, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import { get } from 'lodash';
import { default as React } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { ReactComponent as Empty } from '../../assets/svg/empty.svg';

const TableBodyRow = styled(TableRow)({
    '&:last-child td, &:last-child th': {
        border: 0
    }
});

const DataTableProgress = styled('div')(() => ({
    position: 'absolute',
    inset: 0,
    zIndex: 999,
    display: 'grid',
    placeItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.7)'
}));

type DataTableColumnProps = {
    align?: 'left' | 'right';
    dataIndex: string;
    render?: (row: any) => React.ReactNode;
    sorter?: boolean;
    title: string;
};

export const DataTableColumn = (props: DataTableColumnProps): JSX.Element | null => {
    return null;
};

type DataTableProps = {
    children: any;
    dataPath: string;
    query: any;
    variables?: object;
};

type SortDirectionType = 'asc' | 'desc';

export const DataTable = (props: DataTableProps): JSX.Element => {
    const {
        children,
        dataPath,
        query,
        variables = {}
    } = props;

    const [
        perPage
    ] = React.useState(20);
    const [
        count,
        setCount
    ] = React.useState(0);
    const [
        edges,
        setEdges
    ] = React.useState([]);
    const [
        page,
        setPage
    ] = React.useState(1);
    const [
        sortBy,
        setSortBy
    ] = React.useState('createdAt');
    const [
        sortOrder,
        setSortOrder
    ] = React.useState<SortDirectionType>('desc');
    const [
        columns,
        setColumns
    ] = React.useState<DataTableColumnProps[]>([]);

    React.useEffect(() => {
        setColumns(convertChildrenToColumns()); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children]);

    const toggleSortOrder = (order: SortDirectionType): SortDirectionType => {
        return order === 'asc' ? 'desc' : 'asc';
    };

    const convertChildrenToColumns = (): DataTableColumnProps[] => {
        return React.Children.map(children, element => element.props);
    };

    return (
        <Query
            fetchPolicy="no-cache"
            query={query}
            variables={{
                skip: perPage * (page - 1),
                take: perPage,
                sortBy,
                sortOrder,
                ...variables
            }}
            onError={(err: ApolloError) => {
                //
            }}
            onCompleted={(data: any) => {
                const {
                    count,
                    edges
                } = get(data, dataPath);
                setCount(count);
                setEdges(edges);
            }}
        >
            {({ loading }: QueryResult) => (
                <div>
                    <TableContainer
                        component={Paper}
                        variant="outlined"
                        sx={{
                            position: 'relative'
                        }}
                    >
                        {loading && (
                            <DataTableProgress>
                                <CircularProgress />
                            </DataTableProgress>
                        )}
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map(({ align, dataIndex, sorter, title }: DataTableColumnProps) => (
                                        <TableCell
                                            key={dataIndex}
                                            align={align}
                                            sortDirection={sorter && sortBy === dataIndex ? sortOrder : false}
                                            sx={{
                                                fontWeight: 700
                                            }}
                                        >
                                            {sorter
                                                ?
                                                <TableSortLabel
                                                    active={sortBy === dataIndex}
                                                    direction={sortBy === dataIndex ? sortOrder : 'asc'}
                                                    onClick={() => {
                                                        const order = sortBy === dataIndex ? toggleSortOrder(sortOrder) : 'asc';
                                                        setSortBy(dataIndex);
                                                        setSortOrder(order);
                                                    }}
                                                >
                                                    {title}
                                                </TableSortLabel>
                                                :
                                                <span>
                                                    {title}
                                                </span>
                                            }
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {edges.map((row: any) => (
                                    <TableBodyRow key={row.id}>
                                        {columns.map(({ align, dataIndex, render }: DataTableColumnProps) => {
                                            return (
                                                <React.Fragment key={dataIndex}>
                                                    {render
                                                        ?
                                                        render(row)
                                                        :
                                                        <TableCell align={align}>
                                                            {get(row, dataIndex)}
                                                        </TableCell>
                                                    }
                                                </React.Fragment>
                                            );
                                        })}
                                    </TableBodyRow>
                                ))}
                            </TableBody>
                        </Table>
                        {loading === false && edges.length === 0 && (
                            <Box padding={2}>
                                <Stack
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <SvgIcon
                                        component={Empty}
                                        inheritViewBox
                                        sx={{
                                            fontSize: 64
                                        }}
                                    />
                                    <Typography
                                        color="text.disabled"
                                        textAlign="center"
                                    >
                                        Мэдээлэл олдсонгүй
                                    </Typography>
                                </Stack>
                            </Box>
                        )}
                    </TableContainer>
                    <Box marginY={4}>
                        <Box textAlign="center">
                            <Pagination
                                count={Math.ceil(count / perPage)}
                                page={page}
                                onChange={(e, value) => setPage(value)}
                                color="primary"
                                sx={{
                                    display: 'inline-block'
                                }}
                                renderItem={item => (
                                    <PaginationItem
                                        {...item}
                                        components={{
                                            previous: () => <ChevronLeft size={18} />,
                                            next: () => <ChevronRight size={18} />
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                </div>
            )}
        </Query>
    );
};
import type { } from '@mui/lab/themeAugmentation';
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    shape: {
        borderRadius: 8
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#0c4da2'
        },
        success: {
            main: '#13a456'
        },
        error: {
            main: '#ec6264'
        }
    },
    typography: {
        h1: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h2: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h3: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h4: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h5: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        h6: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        subtitle1: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        subtitle2: {
            fontFamily: 'Nunito',
            fontWeight: 700
        },
        body1: {
            fontFamily: 'Nunito',
            fontWeight: 400
        },
        body2: {
            fontFamily: 'Nunito',
            fontWeight: 400
        },
        button: {
            fontFamily: 'Nunito',
            fontWeight: 700,
            textTransform: 'none'
        },
        caption: {
            fontFamily: 'Nunito',
            fontWeight: 400
        },
        overline: {
            fontFamily: 'Nunito',
            fontWeight: 400
        }
    },
    components: {
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true
            }
        },
        MuiButton: {
            styleOverrides: {
                outlined: {
                    backgroundColor: '#fff'
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontFamily: 'Nunito',
                    fontWeight: 700
                },
                labelSmall: {
                    lineHeight: '24px'
                }
            }
        }
    }
});